import axios from "axios";
import { pipelineApiUrl,pipelineDataUrl } from "../constants/apis";
export async function pipelineApi(ownerId, quarterId) {
  console.log(ownerId, quarterId,"filter pipeline api call")
  let response = await axios({
    method: "post",
    url: pipelineApiUrl,
    data :{ownerId:ownerId, quarterId:quarterId}
  });

  const channelSequence = [
    "SQL (Sales Qualified)",
    "Requirements Analysis",
    "Proposal/Price Quote",
    "Final Consideration",
  ];

  let pipelineData = response.data;
  console.log(pipelineData,'pipelineData');
  let filteredObject = pipelineData.filter((item) => {
    return channelSequence.includes(item.pipeline);
  });
  for (let i = filteredObject.length - 2; i >= 0; i--) {
    filteredObject[i].sum += filteredObject[i + 1].sum;
  }
  return filteredObject;
}
export async function ownerPipelineApi(channelId, quarterId) {
  let response = await axios({
    method: "post",
    url: pipelineDataUrl,
    data :{channelId: channelId, quarterId:quarterId}
  });
  return response.data;
}
