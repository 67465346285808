import DashboardPipelineHeader from "../DashboardPipelineHeader/DashboardPipelineHeader";
import SalesFunnel2 from "../SalesFunnel/SalesFunnel.js";
import "../DashboardPipeline/DashboardPipeline.scss";
const DashboardPipeline = (props) => {
  return (
    <>
      {/* <DashboardPipelineHeader /> */}
      {/* <SalesFunnel2 amount={props.amount} pipelineData={props.pipelineData}/> */}
      <div className="horizontal_line"></div>
    </>
  );
};
export default DashboardPipeline;
