import "./SalesFunnel.scss";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useState, useEffect, useContext } from "react";
import { pipelineApi } from "../../utility/pipelineApi";
import { myContext } from "../OverallStatus/OverallStatus";
import PipelineLabels from "../PipelineLabels/PipelineLabels";
import { element } from "prop-types";
Chart.register(CategoryScale);

export default function SalesFunnel2(props) {
  // const [pipelineData, setPipelineData] = useState([]);
  let { isAllOwners, updateIsAllOwners } = useContext(myContext);
  // let isAllOwners=true;
  // const {selectedFilters}=useContext(myContext);
  let info;

  // async function getPipelineApi() {
   
  //   info = await pipelineApi();
  //   setPipelineData(info);
    
  //   // console.log("info", info);
  // }
  // useEffect(() => {
  //   // getPipelineApi();
  //   console.log(props.pipelineData,"pipeline")
  // }, []);
  // setPipelineData(props.pipelineData)
  let pipelineData=[];
  pipelineData=props.pipelineData
  const sumValues = pipelineData.map((item) => item.sum);

  const channelData = props.amount;
  let totalProjected = 0;
  channelData.forEach((element) => {
    if (!element.hasOwnProperty("channelName"))
      totalProjected = window.parseInt(element.totalProjectAmount);
  });
  let target = [],targetGraphDisplay=[];
  let targetSteps = [5, 4, 3, 2];

 
  let multipliedData;
  if (isAllOwners) {
    multipliedData = pipelineData.map((item, index) => {
      const targetStep = targetSteps[index];
      const multipliedSum = item.sum * targetStep;
      return { sum: multipliedSum };
    });
  } else {
    multipliedData = pipelineData;
  }
  if(totalProjected===0 && multipliedData.filter((data)=>(data.sum===0)).length===multipliedData.length){
    targetSteps.forEach((each)=>{
      targetGraphDisplay.push(each*100000000)
      target.push(0)
    })

  }
  else
  {
    for (let i = 0; i < targetSteps.length; i++) {
      target[i] = totalProjected * targetSteps[i];
    }
    targetGraphDisplay=[...target]
  }
  const chartData = {
    labels: ["SQL", "RA", "PS", "FC"],
    datasets: [
      {
        label: "Users Gained ",
        data: multipliedData.map((item) => item.sum),
        type: "bar",
        backgroundColor: ["#54A6C0"],
        offset: false,
        borderColor: "white",
        borderWidth: { top: 0, right: 1, left: 1 },
        barPercentage: 1,
        categoryPercentage: 1,
      },
      {
        label: "Users left",
        data: targetGraphDisplay && targetGraphDisplay.map((item) => item),
        borderColor: "#ffffff",
        borderWidth: { top: 0, right: 1, left: 1 },
        backgroundColor: "#EDEDED",
        // backgroundColor:"red",
        type: "bar",
        barPercentage: 1,
        categoryPercentage: 1,
        offset: false,
      },
    ],
  };
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        display: false,
        min: 0,
        max: Math.max(target),

        ticks: {
          stepSize: Math.max(target) / 10,
        },
      },
      x: {
        display: false,
        stacked: true,
      },
    },
  };
  useEffect(() => {}, []);

  const chartStyling = {
    id: "chartBackground",
    afterDatasetsDraw(chart) {
      const {
        ctx,
        chartArea: { left, right, width, top, bottom, height },
      } = chart;
      ctx.save();
      ctx.strokeStyle = "white";
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(left, top);
      ctx.lineTo(right, top);
      ctx.lineTo(right, 180);
      ctx.lineTo(left, top);
      ctx.stroke();
      ctx.restore();
      ctx.fillStyle = "white";
      ctx.fill();
    },
  };
  return (
    <div className="sales-funnel">
      <div style={{ maxWidth: "100%", maxHeight: "226.79px" }}>
        <Bar
          className="bar1"
          height="1001"
          width="2003"
          data={chartData}
          options={chartOptions}
          plugins={[chartStyling]}
        ></Bar>
      </div>
      {/* <div> */}
      {pipelineData.length > 0 && (
        <PipelineLabels target={target} pipelineData={pipelineData} />
      )}
      {/* </div> */}
    </div>
  );
}
