import "./Toast.scss";
import cancel from "../../../assets/images/cancel.png"
import { useState } from "react";
const Toast =()=>{
    const [toast,setToast]=useState(true);
    function handleToast(){
        setToast(false)
    }
return (
    <>
    {
        toast &&
    <div className="toast">
        <div className="toast-text">Success</div>
        <button className="cancel" onClick={handleToast}><img src={cancel} height="20px" width="20px" alt="cancel"></img></button>
    </div>
    }
    </>
)
}
export default Toast;