import "./ChannelViewDetailsCard.scss";

const ChannelViewDetailsCard = (props) => {
  const originalDataFormatted =
    props.originaldata &&
    props.originaldata.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    });
  return (
    <>
      <div className="card-details">
        <div className="channelview-amount">
          <div
            className="card-amount tag"
            // style={{ color: props.color }}
            data-originaldata={props.originaldata}
          >
            {props.amount}
            {props.originaldata !== undefined && (
              <span className="tag-text">{originalDataFormatted}</span>
            )}
          </div>
          <div className="channelview-percentile">%</div>
        </div>
        <div className="card-description">{props.description}</div>
      </div>
    </>
  );
};

export default ChannelViewDetailsCard;
