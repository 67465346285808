import { sortAllOwnersApi } from "./sortAllOwnersApi";
import axios from "axios";
import { getQuarterApiUrl } from "../constants/apis";
export async function quartersApi(ownerId, quarterId) {
  const response = await axios({
    method: "post",
    url: getQuarterApiUrl, //post->getQuarter get->quarter
    data: { ownerId: ownerId, quarterId: quarterId },
  });

  let quartersApiData = response.data;
  let filteredQuartersData = [{}];
  if (quartersApiData) filteredQuartersData = sortAllOwnersApi(quartersApiData);
  return filteredQuartersData;
}
