import React from "react";
import "./OverallProgressbar.scss";
const ProgressBar = (props) => {
  
  let allChannelData = props.amount.filter((item)=>item.hasOwnProperty('channelName'));
  let userTarget = 0;
  allChannelData.forEach((element) => {
    if (element !== undefined) {
      userTarget += element.targetAmount;
    }
  });
  let returnChannelTargetProgress = (channelName) => {
    let channel = allChannelData.filter(
      (channel) => channel.channelName === channelName
    );

    let percentage =
      userTarget === 0 || channel[0] === undefined
        ? 0
        : (channel[0].targetAmount * 100) / userTarget;
    return channel[0] === undefined
      ? 0
      : channel[0].channelName === "Existing Business"
      ? percentage.toFixed(2)
      : percentage.toFixed(2);
  };
  let returnChannelAchievedProgress = (channelName) => {
    let channel = allChannelData.filter(
      (channel) => channel.channelName === channelName
    );

    let percentage =
      channel[0] === undefined || channel[0].commitedAmount === 0
        ? 0
        : (channel[0].committedAmount * 100) / userTarget;
    return percentage.toFixed(2);
  };
 
  let channelColors = [
    "#611FED",
    "#54A6C0",
    "#76CAFD",
    "#C8DB57",
    "#76C894",
    "#FF9518",
    " #CC2877",
    "#17f20b",
  ];
  return (
    <>
      <div className="progress-bar-container-target">
        <div className="progress-title">Target</div>
        <div className=" progress-bar">
          {allChannelData.map(
            (item, index) =>
              index !== allChannelData.length &&
              returnChannelTargetProgress(item.channelName) !== 0 && (
                <div
                  className="outbound-progress"
                  style={{
                    "--channel-width": `${returnChannelTargetProgress(
                      item.channelName
                    )}%`,
                    "--channel-backgroundColor": `${channelColors[index]}`,
                  }}
                ></div>
              )
          )}
        </div>
      </div>
      <div className="progress-bar-container-achieved">
        <div className="progress-title">Achieved</div>
        <div className=" progress-bar">
          {allChannelData.map(
            (item, index) =>
              index !== allChannelData.length &&
              returnChannelAchievedProgress(item.channelName) !== 0 && (
                <div
                  className="outbound-progress"
                  style={{
                    "--channel-width": `${returnChannelAchievedProgress(
                      item.channelName
                    )}%`,
                    "--channel-backgroundColor": `${channelColors[index]}`,
                  }}
                ></div>
              )
          )}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ProgressBar;
