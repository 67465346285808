import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./IndividualDonutChart.scss";
import { Tooltip } from "@mui/material";

const IndividualDonutChart = ({ channelsData }) => {
  const crore = 10000000;
  const donutLabelsData = [];
  const donutLabelsPercent = [];
  const donutColors = [];
  const combinedData = [];
  const channelsTotalAmounts = [];

  const channelColors = {
    "Account Mining": "#76C894",
    Outbound: "#611FED",
    "Marketing Inbound": "#54A6C0",
    "CXO Sales": "#CC2877",
    "Network Inbound": "#C8DB57",
    Partner: "#76CAFD",
    "Existing Business": "#FF9518",
    "Gap target":"#B8B8B8",
  };

  const totalGapToTarget = channelsData.overviewData?.map((item, index) => {
    return item.gapToTarget;
  });

  const totalTargetAmount = channelsData.overviewData.map((item, index) => {
    return item.targetAmount;
  });

  const totalCommitedAmount = channelsData.data.reduce((sum, item) => {
    return sum + parseFloat(item.committedAmount || 0);
  }, 0);
  

  const gapToTargetSum = channelsData.data.reduce((total, channel) => {
    const gap =
      (Number(channel.targetAmount) - Number(parseFloat(channel.committedAmount) || 0)) / crore;
    const positiveGap = Math.max(parseFloat(gap), 0);
  
    return total + positiveGap;
  }, 0);

  const gapToTargetSumTooltipValue = channelsData.data.reduce((total, channel) => {
    const gap = channel.targetAmount - channel.committedAmount;
    return total + gap;
  }, 0);
  
  const individualDonutChannelsGapToTargetAmount = channelsData.data.map((item) => {
    return item.gapToTarget;
  })

  console.log(individualDonutChannelsGapToTargetAmount,'gapToTargetSumgapToTargetSum');
  const totalTargetSum = channelsData.data.reduce((total, channel) => {
    const gap = Number(channel.targetAmount) / crore;
    return total + parseFloat(gap);
  }, 0);

  // const totalTargetAmountPercent = ((Number((JSON.stringify(totalGapToTarget).slice(0,-5)).slice(2)) * 100) / Number((JSON.stringify(totalTargetAmount).slice(0,-5)).slice(2)))
  const totalTargetAmountPercent = (
    (((totalTargetSum * crore).toFixed(0) -
      (gapToTargetSum * crore).toFixed(0)) /
      (totalTargetSum * crore).toFixed(0)) *
    100
  ).toFixed(1);
  const commitedAmount =
    Number(JSON.stringify(totalTargetAmount).slice(0, -5).slice(2)) -
    gapToTargetSum;
  const isGapEqual = totalGapToTarget === totalTargetAmount;
  const individualTotalPercentage =
    (totalTargetSum * crore).toFixed(0) - (gapToTargetSum * crore).toFixed(0);
  const individualtotalGapAmount = 100 - individualTotalPercentage;

  const individualDonutAchievedPercentage = (
    (((totalTargetSum * crore).toFixed(0) -
      (gapToTargetSum * crore).toFixed(0)) /
      (totalTargetSum * crore).toFixed(0)) *
    100
  ).toFixed(2);

  const donutChannelData = () => {
    channelsData?.data.forEach((channel, index) => {
      const channelsLabels = channel.channelName;
      donutLabelsData.push(channelsLabels);

      let percent = (
        (channel.committedAmount / channel.targetAmount) *
        100
      ).toFixed(1);
      // let channelsTotalAmount = 0;
      // if (channel.targetAmount > channel.committedAmount) {
      //  const channelsTotalAmount = (
      //     (channel.targetAmount - channel.committedAmount) / crore
      //   ).toFixed(2);
      // }
      let channelsTotalAmount;
      // if (channel.targetAmount >= channel.committedAmount || channel.targetAmount <= channel.committedAmount) {
        channelsTotalAmount = (channel.committedAmount / crore).toFixed(2);
      // }

      combinedData.push({
        percent: percent,
        totalAmount: channelsTotalAmount,
      });
      channelsTotalAmounts.push(channelsTotalAmount);

      percent = Math.min(percent, 100);
      donutLabelsPercent.push(percent);
      donutColors.push(channelColors[channelsLabels]);
      
    });

    let gapTargetPercent = (
      (totalGapToTarget / totalTargetAmount) *
      100
    ).toFixed(1);

   

    if (isNaN(gapTargetPercent)) {
      gapTargetPercent = 100; 
    } else if (gapTargetPercent === "0") {
      gapTargetPercent = 0;
    } else {
      gapTargetPercent = Math.min(gapTargetPercent, 100);
    }

    if (parseFloat(individualDonutAchievedPercentage) !== 0) {
      donutLabelsData.push("Gap target");
      donutLabelsPercent.push(parseFloat(individualDonutAchievedPercentage));
      donutColors.push(isGapEqual ? "#000000" : channelColors["Gap target"]);
    } else {
      donutLabelsData.push("Gap target");
      donutLabelsPercent.push(totalGapToTarget * crore);
      donutColors.push("#B8B8B8");
    }
    
      // Add totalGapToTarget to the data
      combinedData.push({
        percent: individualDonutAchievedPercentage,
        totalAmount: gapToTargetSum.toFixed(2),
      });
  
    return {
      donutLabelsData,
      donutLabelsPercent,
      donutColors,
      channelsTotalAmounts,
      combinedData,
    };
  };

  const result = donutChannelData();

  const donutData = {
    labels: result.donutLabelsData,
    datasets: [
    // {
    //     data: result.combinedData.map((item) => item.percent),
    //     backgroundColor: result.donutColors,
    //     label: "Percentage",
    //   },
      {
        data: result.combinedData.map((item) => item.totalAmount),
        backgroundColor: result.donutColors,
      },
     
    ],
  };

  const options = {
    cutout: 105,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (context) => {
            const index = context[0].dataIndex;
            return result.donutLabelsData[index];
          },
          label: (context) => {
            const label = context.label;
            const value = context.formattedValue;
      
            if (label === "Total Gap Target") {
              return `${value} Cr`;
            } else {
              return `${value} Cr`;
            }
          },
        },
      },
      center: {
        color: "#b3b1ad",
        fontStyle: "bold",
        lineHeight: 1.6,
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
  };

  return (
    <div className="individualDoughNutChart">
      <div className="individualDoughnutChartSection">
        <div className="doughnutChart">
          <Doughnut
            data={donutData}
            options={options}
            className="doughNutChart"
          />
        </div>

        <div className="individualDoughNutChartOverallTarget">
          <Tooltip
            title={`${
              totalCommitedAmount
            }`}
            arrow
            placement="bottom"
          >
            <div className="individualDoughNutChartOverallTargetLabel">
              {(totalCommitedAmount / crore).toFixed(2)} Cr
            </div>
          </Tooltip>
          /
          <Tooltip
            title={`${(totalTargetSum * crore).toFixed(0)}`}
            arrow
            placement="bottom"
          >
            <div className="individualDoughNutChartTotalTargetLabel">
             {parseFloat(totalTargetSum).toFixed(2)} Cr
            </div>
          </Tooltip>
          <div className="doughNutChartOverallTargetPercentage">
            {`(${individualDonutAchievedPercentage}%)`}
          </div>
          {/* <div className="individualContributionTarget">
        <Tooltip title={`${((totalTargetSum) * crore).toFixed(0) - ((gapToTargetSum) * crore).toFixed(0)}`} arrow placement="bottom">
        <div>{(totalTargetAmountPercent)} %</div>
        </Tooltip>
        <span>Contribution to Target</span>
      </div> */}
        </div>
        <div className="individualDoughNutChartOverallLabel">
          Achieved
        </div>
      </div>

      <div className="individualDoughNutChartTarget">
        <Tooltip
           title={`${(gapToTargetSumTooltipValue).toFixed(0)}`}
           arrow
           placement="bottom"
         >
           <div>{(gapToTargetSum).toFixed(2)} Cr</div>
         </Tooltip>
        <br />
        <span>Gap Target</span>
      </div>
    </div>
  );
};

export default IndividualDonutChart;
