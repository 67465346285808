// import "./HorizontalProgressBar.scss";
// import targetFlag from "../../../assets/images/targetFlag.svg";

// const HorizotalProgressBar = (props) => {
//   return (
//     <>
//       <div className="tooltip width">
//         <img
//           src={targetFlag}
//           style={{
//             position: "relative",
//             left: `${props.targetPosition}%`,
//             top: "3.7px",
//           }}
//           alt=""
//         />
//         <div
//           className="tooltiptext "
//           style={{ position: "absolute", left: `${props.targetPosition}%` }}
//         >
//           {props.flagValue}
//         </div>
//       </div>
//       <div
//         className="HorizotalProgressBar"
//         style={{
//           height: "14px",
//           width: "100%",
//           marginRight: "10px",
//           boxSizing: "border-box",
//           backgroundColor: "#EDEDED",
//         }}
//       >
//         <div
//           className="HorizotalProgressBar-div"
//           style={{
//             height: "14px",
//             width: `${props.committedPercentage}%`,
//             boxSizing: "border-box",
//             backgroundColor: props.committedColor,
//           }}
//         ></div>
//         <div
//           style={{
//             width: `${props.projectedPercentage}%`,
//             boxSizing: "border-box",
//             backgroundColor: props.projectedColor,
//             height: "14px",
//           }}
//         ></div>
//         <div
//           style={{
//             width: `${props.optimisticPercentage}%`,
//             boxSizing: "border-box",
//             backgroundColor: props.optimisticColor,
//             height: "14px",
//           }}
//         ></div>
//       </div>
//     </>
//   );
// };
// export default HorizotalProgressBar;
import "./HorizontalProgressBar.scss";
import targetFlag from "../../../assets/images/targetFlag.svg";

const HorizotalProgressBar = (props) => {
  return (
    <>
      <div className=" width">
        <div
          className="flag-tooltip"
          style={{
            position: "relative",
            left: `${props.targetPosition}%`,
            top: "3.7px",
          }}
        >
          <img src={targetFlag} alt="" />
          <div className="flag-tooltiptext ">{props.flagValue}</div>
        </div>
      </div>
      <div
        className="HorizotalProgressBar"
        style={{
          height: "14px",
          width: "100%",
          marginRight: "10px",
          boxSizing: "border-box",
          backgroundColor: "#EDEDED",
          position:"relative"
        }}
      >
        <div
          className="HorizotalProgressBar-div"
          style={{
            height: "14px",
            width: `${props.committedPercentage}%`,
            boxSizing: "border-box",
            backgroundColor: props.committedColor,
            position:"absolute",
          }}
        ></div>
        <div
          style={{
            width: `${props.projectedPercentage}%`,
            boxSizing: "border-box",
            backgroundColor: props.projectedColor,
            position:"absolute",
            height: "14px",
          }}
        ></div>
        <div
          style={{
            width: `${props.optimisticPercentage}%`,
            boxSizing: "border-box",
            backgroundColor: props.optimisticColor,
            position:"absolute",
            height: "14px",
          }}
        ></div>
      </div>
    </>
  );
};
export default HorizotalProgressBar;