import React, { useEffect, useState } from "react";
import { Typography, Tooltip } from "@mui/material";
import { Progress } from "rsuite";
import "./IndividualHorizontalBar.scss";
import ideaIcon from "../../assets/images/idea.svg";

const IndividualHorizontalBar = (props) => {
  const { individualBarData, keys, individualpipelineData } = props;
  const [filteredKey, setFilteredKey] = useState([]);
  const [quaterUpdate, setQuaterUpdate] = useState({});


  useEffect(() => {
    if (individualBarData) {
      setQuaterUpdate(individualBarData);
    } else {
      setQuaterUpdate({});
    }
    setFilteredKey(keys);
  }, [keys, individualBarData]);

  return (
    <div className="IndividualHorizontalContainer">
      {filteredKey?.map((item, index) => {
        const channelData =
          Object.keys(quaterUpdate).length > 0
            ? quaterUpdate[item.channelId]
            : {};
        if (channelData) {
 
          //   const channelData = individualBarData[item.channelId];
          const dealStages = channelData?.dealStages || {};
          const dealsKeys = Object.keys(dealStages);
          const crore = 10000000;
          const deals = 3000000;
          let pipelineTotalDeals;

          return (
            <div className="horizontalBarMainSection" key={item}>
              <Typography className="horizontalBarName">
                <h2 className="horizontalBarSectionHeader">
                  {item.channelName}
                </h2>
              </Typography>
              <div className="IndivalDealsPipelineNames">
                {dealsKeys.map((stageName) => {
                  const stageData = dealStages[stageName];
                  const pipelineToatlTarget = stageData.targetAmount / crore;
                  const nonNegativePipelineToatlTarget =
                    pipelineToatlTarget < 0 ? 0.0 : pipelineToatlTarget;
                  // const nonNegativePipelineToatlTarget = isNaN(pipelineToatlTarget) || pipelineToatlTarget === undefined
                  // ? 0
                  // : pipelineToatlTarget;
                  const IndividualpipelineToatlTarget = isNaN(
                    pipelineToatlTarget
                  )
                    ? "0.0"
                    : `${pipelineToatlTarget}`;
                  const piplelineCommitedTarget =
                    stageData.committedAmount / crore;
                  // const nonNegativePiplelineCommitedTarget = piplelineCommitedTarget < 0 ? 0.00 : piplelineCommitedTarget;
                  const nonNegativePiplelineCommitedTarget =
                    isNaN(piplelineCommitedTarget) ||
                    piplelineCommitedTarget === undefined
                      ? 0
                      : piplelineCommitedTarget;
                  const IndividualpiplelineCommitedTarget = isNaN(
                    piplelineCommitedTarget
                  )
                    ? "0.0"
                    : `${piplelineCommitedTarget}`;
                  const pipelineTotalPendings =
                    IndividualpipelineToatlTarget -
                    IndividualpiplelineCommitedTarget;
                  const nonNegativePipelineTotalPendings =
                    pipelineTotalPendings < 0 ? 0.0 : pipelineTotalPendings;
                  pipelineTotalDeals = Math.round(
                    (IndividualpipelineToatlTarget * crore -
                      IndividualpiplelineCommitedTarget * crore) /
                      deals
                  );
                  const nonNegativePipelineTotalDeals =
                    pipelineTotalDeals < 0 ? 0.0 : pipelineTotalDeals;
                  const tooltipcommittedAmount =
                    isNaN(stageData.committedAmount) ||
                    stageData.committedAmount === undefined
                      ? 0
                      : stageData.committedAmount;

                  if (stageData) {
                    return (
                      <div key={stageName}>
                        <Typography className="horizontalBarName">
                        
                          {stageName}
                        </Typography>
                        <div className="achivedSection">
                          <Tooltip
                            title={`${
                              Math.round(IndividualpiplelineCommitedTarget * crore)
                            }`}
                            arrow
                            placement="bottom"
                          >
                            <span style={{ marginRight: "5px" }}>
                              {nonNegativePiplelineCommitedTarget.toFixed(2)} Cr
                            </span>
                          </Tooltip>
                          /
                          <Tooltip
                            title={`${Math.round(stageData.targetAmount)}`}
                            arrow
                            placement="bottom"
                          >
                            <span style={{ marginLeft: "5px" }}>
                              {nonNegativePipelineToatlTarget.toFixed(2)} Cr
                            </span>
                          </Tooltip>
                          <Typography className="achivedText">
                          
                            Target Achieved
                          </Typography>
                        </div>
                        <div className="horizontalUpperGrayBar">
                          <Progress.Line
                            percent={IndividualpipelineToatlTarget}
                            strokeWidth={30}
                            strokeColor="#D9D9D9"
                            trailColor="#D9D9D9"
                            strokeLinecap="square"
                            style={{ height: "", width: "30%" }}
                          />
                        </div>
                        <div className="">
                          <Progress.Line
                            percent={IndividualpiplelineCommitedTarget}
                            strokeWidth={30}
                            strokeColor="#008000"
                            trailColor="#D9D9D9"
                            strokeLinecap="square"
                            style={{ height: "", width: "30%" }}
                          />
                        </div>

                        <div className="horizontalBarDealsAndPendingSection">
                          <div className="dealsSection">
                            <div className="dealsNumbers">
                              {Math.round(nonNegativePipelineTotalDeals)}
                              <span>Deals</span>
                            </div>
                            <Typography className="dealContent">
                            
                              To Recover
                            </Typography>
                          </div>

                          <div className="gapTargetSec">
                            <Tooltip
                              title={`${
                                stageData.targetAmount - tooltipcommittedAmount
                              }`}
                              arrow
                              placement="bottom"
                            >
                              <div className="gapTargetNumbers">
                                {nonNegativePipelineTotalPendings.toFixed(2)}Cr
                                <br />
                              </div>
                            </Tooltip>
                            <Typography className="gapTargetname">
                            
                              Pending
                            </Typography>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              {item.channelName && (
                <div className="horizontalBarSectionBottomLabel">
                  <img src={ideaIcon} alt="idea icon" />
                  <div>
                    You need at least {Math.round(Math.max(pipelineTotalDeals * 3 * 3, 0))} Leads or {Math.round(Math.max(pipelineTotalDeals * 3, 0))} Meetings to reach the Target.
                  </div>
                </div>
              )}
              <div className="bottomBorder"></div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default IndividualHorizontalBar;
