import DropdownFilter from "../commonComponents/DropdownFilter/DropdownFilter";
import {
  channel,
  queterly_summery_data,
  userdata /*  for user view*/,
  userdatalist,
} from "../../constants/dropdown_data";

import { userListApi } from "../../utility/userListApi.js";
import "../OverAllStatusHeader/OverAllStatusHeader.scss";
import { useState, useEffect, useContext } from "react";
import { getOwnersChannelData } from "../../utility/getOwnersChannelData.js";
import { allOwners } from "../../utility/allOwners";
import { quartersApi } from "../../utility/quartersApi.js";
import userviewApi from "../../utility/userviewApi.js";
import { UserContext } from "../../App";
import { myContext } from "../OverallStatus/OverallStatus";
import yearIcon from "../../assets/images/yearIcon.svg";
import channelIcon2 from "../../assets/images/channelIcon2.svg";
import allOwnersIcon2 from "../../assets/images/allOwnersIcon2.svg";
import { salesDropdown } from "../../utility/salesDropdownList";
import { pipelineApi } from "../../utility/pipelineApi";
const OverAllStatusHeader = (props) => {
  const { ownerName, ownerId, loginName } = useContext(UserContext);
  const { isAllOwners, setIsAllOwners } = useContext(myContext);
  const [ownersList, setOwnersList] = useState();
  const [filter1, setFilter1] = useState(0);
  const [filter2, setFilter2] = useState(ownerId);
  const [filter3, setFilter3] = useState(0);
  const [one, setOne] = useState(0);
  const [two, setTwo] = useState(0);
  const sales = "FY 2024";
  const channels = "Channel";
  const allDropdowns = [
    { options: channel, label: channels, icon: channelIcon2 },
    { options: ownersList, label: ownerName, icon: allOwnersIcon2 },
    { options: salesDropdown(), label: sales, icon: yearIcon },
  ];


  if (filter2 === 0) {
    setIsAllOwners(true);
  } else {
    setIsAllOwners(false);
  }

  const handleFilter = (selectedOption, selectedId) => {
    if (selectedId === 0 && selectedOption === 1) {
      /*  for user view*/
      setOne(selectedId);
      setTwo(selectedOption);
      // console.log("you got it")
    } else {
      setOne(0);
      setTwo(0);
    }
    if (selectedId === 0) {
      setFilter1(selectedOption);
    } else if (selectedId === 1) {
      setFilter2(selectedOption);
    } else if (selectedId === 2) {
      setFilter3(selectedOption);

    }
  
  };

  const handleGo = async (filter1, filter2, filter3) => {
    let transformedData,pipelineData;
    if (filter1 === 0 && filter2 === 0 && filter3 === 0) {
      transformedData = await allOwners();
    } else if (filter1 === 0 && filter3 === 0 && filter2 !== 0) {
      transformedData = await getOwnersChannelData(filter2);
    } else if (filter1 === 1) {
      /*  for user view*/
      transformedData = await userviewApi();
      // transformedData=userdata;
    } else {
      /*--------------------------------------------------------------- */
      transformedData = await quartersApi(filter2, filter3);
    }
    pipelineData = await pipelineApi(filter2,filter3);
    props.pipelineData(pipelineData);
    props.setFilter(transformedData);

  };
  useEffect(() => {
    async function getUsers() {
      let ownerList = await userListApi();
      setOwnersList(ownerList);
    }
    getUsers();

  }, []);
  useEffect(() => {
    handleGo(filter1, filter2, filter3);
  }, [filter1, filter2, filter3]);

  return (
    <>
      <div className="OverAllStatusHeader">
        {/* <div className="filters-container"> */}
        {/* <div className="filters"> */}
        {ownersList &&
          allDropdowns.map((each, index) => (
            <>
              <DropdownFilter
                options={each.options}
                icon={each.icon}
                label={each.label}
                id={index}
                handleFilter={handleFilter}
                // disabledOptions={ownersList}
                disabledOptions={
                  ownerName === "Viswa" ? ownersList : []
                } /*  for user view*/
              />
            </>
          ))}
        {/* </div> */}
      </div>
      {/* <div className="go">
          <button className="go-button">Go</button>
        </div> */}
      {/* </div> */}
    </>
  );
};
export default OverAllStatusHeader;
