export function salesDropdown(){
    const currentDate=new Date();
    let salesDropdownList=[];
    // const currentMonth=currentDate.getMonth()+1; //getMonth() takes months from 0 to 11
    // console.log(currentDate.getFullYear(),"currentMonth")
    // if(currentMonth>=4 && currentMonth<=6)
    // {
    //     salesDropdownList=[ {
    //         id: 0,
    //         name: "FY 2024",
    //       },
    //       {
    //         id: 1,
    //         name: "Q1 "+currentDate.getFullYear(),
    //       },]
    // }
    // else if(currentMonth>=7 && currentMonth<=9)
    // {
    //     salesDropdownList=[
    //         {
    //             id: 0,
    //             name: "FY 2024",
    //           },
    //           {
    //             id: 1,
    //             name: "Q1 "+currentDate.getFullYear(),
    //           },
    //           {
    //             id: 2,
    //             name: "Q2 "+currentDate.getFullYear(),
    //           },
           
    //     ]
    // }
    // else if(currentMonth>=10 && currentMonth<=12)
    // {
    //     salesDropdownList=[
    //         {
    //             id: 0,
    //             name: "FY 2024",
    //           },
    //           {
    //             id: 1,
    //             name: "Q1 "+currentDate.getFullYear(),
    //           },
    //           {
    //             id: 2,
    //             name: "Q2 "+currentDate.getFullYear(),
    //           },
    //           {
    //             id: 3,
    //             name: "Q3 "+currentDate.getFullYear(),
    //           },
           
    //     ]
    // }
    // else
    // {
    //     salesDropdownList=[
    //         {
    //             id: 0,
    //             name: "FY 2024",
    //           },
    //           {
    //             id: 1,
    //             name: "Q1 "+currentDate.getFullYear(),
    //           },
    //           {
    //             id: 2,
    //             name: "Q2 "+currentDate.getFullYear(),
    //           },
    //           {
    //             id: 3,
    //             name: "Q3 "+currentDate.getFullYear(),
    //           },
    //           {
    //             id: 4,
    //             name: "Q4 "+currentDate.getFullYear(),
    //           }
           
    //     ]
    // }
    salesDropdownList=[
      {
          id: 0,
          name: "FY "+(currentDate.getFullYear()+1),
        },
        {
          id: 1,
          name: "Q1 "+(currentDate.getFullYear()+1),
        },
        {
          id: 2,
          name: "Q2 "+(currentDate.getFullYear()+1),
        },
        {
          id: 3,
          name: "Q3 "+(currentDate.getFullYear()+1),
        },
        {
          id: 4,
          name: "Q4 "+(currentDate.getFullYear()+1),
        }
     
  ]
    return salesDropdownList;
}
