export const roundOffTwoDecimals = (val) => {
  return Math.round(val * 100) / 100;
};

export const channelDataMassageFn = (data, dealAmount, crore) => {
  const updatedData = data.map((each, index) => {
    const gapToTarget =
      Number(each.targetAmount ? each.targetAmount : 0) -
      Number(each.commitedAmount || each.committedAmount || 0);
    const numberOfDeals = Math.ceil(gapToTarget / dealAmount);
    return {
      ...each,
      id: index,
      channelName: each.channelName,
      toRecover: `${Math.ceil(gapToTarget / dealAmount)} Deals`,
      deviationTarget: `${roundOffTwoDecimals(
        (gapToTarget / Number(each.targetAmount || 0)) * 100
      )}%`,
      gapToTarget: `${roundOffTwoDecimals(gapToTarget / crore)} Cr`,
      percentAchieved: `${roundOffTwoDecimals(
        (Number(each.commitedAmount || each.committedAmount || 0) /
          Number(each.targetAmount || 0)) *
          100
      )} %`,
      achievedTarget: `${roundOffTwoDecimals(
        Number(each.commitedAmount || each.committedAmount || 0) / crore
      )} Cr`,
      finalTarget: `${roundOffTwoDecimals(
        Number(each.targetAmount || 0) / crore
      )} Cr`,
      leads: numberOfDeals * 3,
      meetings: numberOfDeals * 8,
    };
  });
  return updatedData;
};
