export const dashboardApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard";
// for dashboard (get)

export const channelViewApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/channel";
// for channelView (allChannels, FY 2023) (get)

export const userChannelApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/userChannel";
// for channelView(specified user, FY 2023) (post) =>ownerId

export const pipelineApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/pipeline";
// for pipeline graph (post) (quarterId,ownerId)

export const pipelineDataUrl = 
"https://jeeves.divami.com/nestorbackend/dashboard/pipelineData";
// for pipeline graph (post) (quarterId,ownerId)
export const getQuarterApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/getQuarter";
// for channelView (ownerId= 0, quarterId)

export const ownersApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/owners";
// for all owners details

export const channelsApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/channelsDetails";
//for all channels details

export const userViewApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/ownerView";
// for userView (chaannelId =0 for allChannels, quarterId = 0 for FY 2023)

export const saveLogInApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/login";
// for saving login user details (get)=>(mailId, timeStamp)

export const usersAuth =
"https://jeeves.divami.com/nestorbackend/dashboard/users";
//get

export const oAuthUsersApiUrl =
"https://jeeves.divami.com/nestorbackend/auth/signIn";
//for user authorization , post method:body->emailId

export const reloadApiUrl =
"https://jeeves.divami.com/nestorbackend/reload/data";
// for reloading the data, get method

export const individualTargetContributionApiUrl =
"https://jeeves.divami.com/nestorbackend/dashboard/individualTargetAndContributions";