// import "./ImprovementChannelData.scss";
// import redDownArrow from "../../../assets/images/redDownArrow.svg";
// import greenUpArrow from "../../../assets/images/greenUpArrow.svg";
// const ChannelData = ({
//   className,
//   channelOverAllArray,
//   weakOnweakStatus,
//   target,
//   remainingDays,
//   avgDealSize,
//   dealsForTarget,
// }) => {
//   return (
//     <>
//       <table className={className}>
//         <tr className="row1">
//           {channelOverAllArray.map((data) => (
//             <td className="item">
//               <div className="amount-value tooltip">
//                 {data.displayValue}
//                 <span className="row-one-cr"> cr</span>
//                 {data.original !== undefined && (
//                   <span className="tooltiptext">
//                     {data.displayByCommaValue}
//                   </span>
//                 )}
//               </div>
//               <div className="description">{data.display}</div>
//             </td>
//           ))}
//           {remainingDays && (
//             <td className="item">
//               <div className="deals-for-target">{dealsForTarget}</div>
//               <div className="description">Deals for Target</div>
//             </td>
//           )}
//         </tr>
//         <tr className="row2">
//           <td className="target-item-value tooltip">
//             {target.displayValue}
//             {target.original !== undefined && (
//               <span className="tooltiptext">{target.displayByCommaValue}</span>
//             )}
//             <span className="row-two-cr"> cr</span>
//             <span className="target-description">Target</span>
//           </td>
//           {weakOnweakStatus.map((data) => (
//             <td className="status-array">
//               <div className="item">
//                 <div className="difference tooltip">
//                   {data.difference}
//                   <span className="tooltiptext">
//                     {data.displayByCommaValue}
//                   </span>
//                 </div>
//                 <div className="bar"></div>
//                 <span className="percentage tooltip">
//                   {data.percentage}
//                   <span className="tooltiptext">{data.originalPercentage}</span>
//                 </span>
//                 {data.originalDifference >= 0 && (
//                   <img className="green-up-arrow" src={greenUpArrow} alt=""></img>
//                 )}
//                 {data.originalDifference < 0 && (
//                   <img className="red-down-arrow" src={redDownArrow} alt=""></img>
//                 )}
//                 <span className="wow">wow</span>
//               </div>
//             </td>
//           ))}
//           {remainingDays && (
//             <td className="item avgdeal">
//               <div className="avg-deal-size">
//                 {avgDealSize}
//                 <span className="description marginleft">Avg. Deal Size</span>
//               </div>
//             </td>
//           )}
//         </tr>
//       </table>
//     </>
//   );
// };
// export default ChannelData;
import "./ImprovementChannelData.scss";
import redDownArrow from "../../../assets/images/redDownArrow.svg";
import greenUpArrow from "../../../assets/images/greenUpArrow.svg";
const ChannelData = ({
  className,
  channelOverAllArray,
  weakOnweakStatus,
  target,
  remainingDays,
  avgDealSize,
  dealsForTarget,
}) => {
  return (
    <>
      <table className={className}>
        <tr className="row1">
          {channelOverAllArray.map((data) => (
            <td className="item">
              <div className="amount-value dashboard-primary-tooltip">
                {data.displayValue}
                {/* {45.45} */}
                <span className="row-one-cr"> cr</span>
                {data.original !== undefined && (
                  <span className="dashboard-primary-tooltiptext">
                    {data.displayByCommaValue}
                  </span>
                )}
              </div>
              <div className="description">{data.display}</div>
            </td>
          ))}
          {remainingDays && (
            <td className="item">
              <div className="deals-for-target">{dealsForTarget}</div>
              <div className="description">Deals for Target</div>
            </td>
          )}
        </tr>
        <tr className="row2">
          <td className="target-item-value dashboard-secondary-tooltip">
            {target.displayValue}

            {/* {45.45} */}

            <span className="row-two-cr"> cr</span>
            {target.original !== undefined && (
              <span className="dashboard-secondary-tooltiptext">
                {target.displayByCommaValue}
              </span>
            )}
            <span className="target-description">Target</span>
          </td>
          {weakOnweakStatus.map((data) => (
            <td className="status-array">
              <div className="item">
                <span className="difference dashboard-secondary-tooltip">
                  {data.difference}
                  {/* {-43.54} */}

                  <span className="dashboard-secondary-tooltiptext">
                    {data.displayByCommaValue}
                  </span>
                </span>
                <span className="bar"></span>
                <span className="percentage dashboard-secondary-tooltip">
                  {data.percentage}

                  <span className="dashboard-secondary-tooltiptext">
                    {data.originalPercentage}
                  </span>
                </span>
                {data.originalDifference >= 0 && (
                  <img
                    className="green-up-arrow"
                    src={greenUpArrow}
                    alt=""
                  ></img>
                )}
                {data.originalDifference < 0 && (
                  <img
                    className="red-down-arrow"
                    src={redDownArrow}
                    alt=""
                  ></img>
                )}
                <span className="wow">wow</span>
              </div>
            </td>
          ))}
          {remainingDays && (
            <td className="item avgdeal">
              <div className="avg-deal-size">
                {avgDealSize}
                <span className="description marginleft">Avg. Deal Size</span>
              </div>
            </td>
          )}
        </tr>
      </table>
    </>
  );
};
export default ChannelData;
