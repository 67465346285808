// import * as React from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import refreshApi from "../../utility/refreshApi";
// import divami1 from "../../assets/images/divami3.0.1.png";
// import settingsicon from "../../assets/images/Setting.svg";
// import "./DashboardHeader.scss";
// import Menu from "@mui/material/Menu";
// // import MenuItem from "@mui/material/MenuItem";
// import { MenuItem } from "@mui/material";

// import { useContext, useState } from "react";
// import { UserContext } from "../../App";
// import { Button } from "@mui/material";
// import Loader from "react-loader";
// function DashboardHeader({ welcome }) {
//   let settings = ["Logout"];
//   const [refresh, setRefresh] = useState(false);
//   const [anchorElUser, setAnchorElUser] = useState(null);
//   const { ownerName, ownerId, loginName, profilePic, userRole } =
//     useContext(UserContext);
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };
//   if (userRole === "Developer" || userRole === "Admin") {
//     settings = ["Logout", "Refresh"];
//   }
//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };
//   function handleLogOut() {
//     localStorage.removeItem("user");
//     welcome(false);
//     console.log("the setwelcome is : false");
//   }
//   async function handleRefresh() {
//     console.log("it's clicked");
//     setRefresh(true);
//     console.log("refresh1", refresh);
//     let response = await refreshApi(setRefresh);
//     console.log(response);
//     if (response) {
//       setRefresh(true);
//     } else {
//       setRefresh(false);
//     }
//     console.log("dealy happend");
//     console.log("refresh2", refresh);
//     // setRefresh(true);
//   }

//   const handleWelcome = (option) => {
//     if (option === "Logout") {
//       handleLogOut();
//     } else if (option === "Refresh") {
//       handleRefresh();
//     }
//   };

//   return (
//     <>
//       <AppBar position="static" className="AppBar-container">
//         <Container maxWidth="xl">
//           <Toolbar disableGutters className="header-container">
//             <img className="divami-logo-image" src={divami1} alt="" />

//             <Box
//               sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
//             >
//               {/* <Tooltip title="Open settings"> */}
//               <IconButton
//                 className="iconButton-container"
//                 onClick={handleOpenUserMenu}
//                 sx={{ p: 0 }}
//               >
//                 {/* <Avatar src="" /> */}
//                 <img className="profile-pic" src={profilePic} alt="" />
//                 <Typography classes={{ root: "typography-username" }}>
//                   Hi, {loginName}
//                 </Typography>
//               </IconButton>
//               {/* </Tooltip> */}

//               <Menu
//                 sx={{
//                   mt: "50px",
//                 }}
//                 classes={{ root: "menu" }}
//                 id="menu-appbar"
//                 anchorEl={anchorElUser}
//                 anchorOrigin={{
//                   vertical: "top",
//                   horizontal: "right",
//                 }}
//                 keepMounted
//                 transformOrigin={{
//                   vertical: "top",
//                   horizontal: "right",
//                 }}
//                 open={Boolean(anchorElUser)}
//                 onClose={handleCloseUserMenu}
//               >
//                 {settings.map((data, index) => (
//                   <MenuItem
//                     // className={
//                     // index<settings.length-1? "dropdown-item":"last-dropdown-item"
//                     // }
//                     // style={{root:{width:"500px"}}}
//                     classes={{
//                       root:
//                         index < settings.length - 1
//                           ? "dropdown-item"
//                           : "last-dropdown-item",
//                     }}
//                     key={data.id}
//                     value={data.id}
//                     onClick={() => handleWelcome(data)}
//                   >
//                     <div>{data}</div>
//                     {/* {selectedValue === data.name && <img src={selected} alt=""/>} */}
//                   </MenuItem>
//                 ))}
//               </Menu>
//               <img src={settingsicon} alt="settings" />
//             </Box>
//           </Toolbar>
//         </Container>
//       </AppBar>
//       {refresh && (
//         <div className="model-wrapper">
//           <div className="model">
//             {/* <Loader
//               loaded={false}
//               lines={13}
//               length={20}
//               width={10}
//               radius={30}
//               corners={1}
//               rotate={0}
//               direction={1}
//               color="#000"
//               speed={1}
//               trail={60}
//               shadow={false}
//               hwaccel={false}
//               className="spinner"
//               zIndex={2e9}
//               top="50%"
//               left="50%"
//               scale={1.0}
//               loadedClassName="loadedContent"
//             /> */}
//             <div class="lds-roller">
//               <div></div>
//               <div></div>
//               <div></div>
//               <div></div>
//               <div></div>
//               <div></div>
//               <div></div>
//               <div></div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default DashboardHeader;

import "./DashboardHeader.scss";
import refreshApi from "../../utility/refreshApi";
import divami1 from "../../assets/images/divami3.0.1.png";
import settingsicon from "../../assets/images/Setting.svg";
import { UserContext } from "../../App";
import React, { useState, useRef, useEffect, useContext } from "react";
import Toast from "../commonComponents/toast/Toast";
import { Button, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import profilePic1 from "../../assets/images/header-profile.svg";
import { useLocation, useNavigate } from "react-router-dom";

function DashboardHeader({ welcome }) {
  let settings = ["Logout"];
  const [refresh, setRefresh] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showToast, setshowToast] = useState(false);
  const dropdownRef = useRef(null);
  const { ownerName, ownerId, loginName, profilePic, userRole, salesDashboard, financeDashboard } =
    useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (userRole === "Admin") {
    settings = ["Logout", "Refresh"];
  }
  const salesUser = salesDashboard === "Sales";
  const financeUser = financeDashboard === "Finance";

  
  const handleLogOut = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    welcome(false);
  };

  const handleRefresh = async () => {
    setRefresh(true);
    let response = await refreshApi();
    if (response.message === "Request Failed") {
      setRefresh(true);
      window.alert("Request Failed");
    } else {
      setRefresh(false);
      if (refresh === false) {
        window.location.reload(true);
        window.alert("Data Fetch Successful!\nPress OK to reload the page");
      }
      // setshowToast(true);
      // await new Promise((resolve) => setTimeout(resolve, 10000));
      // setshowToast(false);
    }
  };

  const handleSalesDashboard = () => {
    navigate("/salesdashboard");
    handleClose();
  };
  const handleNestor = () => {
    navigate("/");
    handleClose();
  };

  // useEffect(() => {
  //   if (salesUser) {
  //     handleSalesDashboard();
  //   } else {

  //   }
  //   if(financeUser) {
  //     handleNestor();
  //   } else {
      
  //   }
  // }, [salesUser,financeUser]);

  const handleWelcome = (option) => {
    if (option === "Logout") {
      handleLogOut();
    } else if (option === "Refresh") {
      handleRefresh();
    }
  };

  const handleMenuToggle = () => {
    // setShowMenu(!showMenu);
  };
  useEffect(() => {
    // Add event listener to window when the dropdown is open
    if (showMenu) {
      window.addEventListener("click", handleOutsideClick);
    }

    // Clean up event listener when the dropdown is closed
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showMenu]);
  const handleOutsideClick = (event) => {
    // Close the dropdown if clicked outside of it
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      event.target.className != "menu"
    ) {
      setShowMenu(false);
    }
  };

  return (
    <>
      <div className="AppBar-container">
        <img className="divami-logo-image" src={divami1} alt="" />
        <div className="header-right">
          <img
            className="profile-pic"
            src={profilePic}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
          <div className="menu-container" ref={dropdownRef}>
            <button className="iconButton-container" onClick={handleMenuToggle}>
              <span className="typography-username">Hi, {loginName}</span>
            </button>
            {showMenu && (
              <>
                <div className="menu">
                  {settings.map((data, index) => (
                    <div
                      className={
                        index < settings.length - 1
                          ? "dropdown-item-list"
                          : "last-dropdown-item"
                      }
                      key={data.id}
                      onClick={() => handleWelcome(data)}
                    >
                      {data}
                    </div>
                  ))}
                </div>
              </>
            )}
            {/* <img className="settings-icon" src={settingsicon} alt="settings" /> */}
            <IconButton
              onClick={handleMenuClick}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
              {location.pathname === "/salesdashboard" ? (
                <MenuItem onClick={handleNestor}>Finance Dashbaord</MenuItem>
              ) : (
                <MenuItem onClick={handleSalesDashboard}>
                  Sales Dashboard
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
      </div>
      {refresh && (
        <div className="model-wrapper">
          <div className="model">
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      {showToast && <Toast></Toast>}
      
    </>
  );
}

export default DashboardHeader;



