import OverAllStatusHeader from "../OverAllStatusHeader/OverAllStatusHeader";
import OverallStatusCard from "../OverallStatusCard/OverallStatusCard";
import "../OverallStatus/OverallStatus.scss";
import DashboardPipeline from "../DashboardPipeline/DashboardPipeline";
import ChannelView from "../ChannelView/ChannelView";
import { useState, useEffect, createContext, useContext } from "react";
import { allOwners } from "../../utility/allOwners";
import { getOwnersChannelData } from "../../utility/getOwnersChannelData.js";
import ChannelCollection from "../ChannelCollection/ChannelCollection";
import UserView from "../UserView/UserView";
import { userdata } from "../../constants/dropdown_data";
import { UserContext } from "../../App";
export const myContext = createContext();
const OverallStatus = () => {
  const [ChannelData, setChannelData] = useState([]);
  const [user, setUser] = useState(false); /* for user view */
  const [isLoading, setIsLoading] = useState(false);
  const [isAllOwners, setIsAllOwners] = useState(false);
  const { ownerName, ownerId, loginName } = useContext(UserContext);
  const [pipelineData,setPipelineData]=useState([]);
  const aggregatedData = [];
  let overallChannelData;

  const handleIsAllOwners = (newValue) => {
    setIsAllOwners(newValue);
  };
  async function getData() {
    const response = await allOwners();
    setChannelData(response);
  }
  async function getOwnersData(ownnerId) {
    const response = await getOwnersChannelData(ownerId);
    setChannelData(response);
  }
  useEffect(() => {
    if (ownerId === 0) getData();
    else getOwnersData(ownerId);
  }, []);

  const handleFilter = (filteredData) => {
   
    if (filteredData[0].ownerName) {
      setUser(true);
    } else {
      setUser(false);
    }
    setChannelData(filteredData);

 

  };
  const handlePipelineData=(filteredPipelineData)=>{
    setPipelineData(filteredPipelineData);
  }

 

  if (user === true && ChannelData && ChannelData.length > 0) {
    ChannelData.forEach((owner) => {
      owner.channels.forEach((channel) => {
        const existingChannel = aggregatedData.find(
          (item) => item.channelName === channel.channelName
        );

        if (existingChannel) {
          existingChannel.targetAmount += channel.targetAmount;
          existingChannel.committedAmount += channel.committedAmount;
          existingChannel.projectedAmount += channel.projectedAmount;
          existingChannel.gapToTarget += channel.gapToTarget;
          existingChannel.optimisticAmount += channel.optimisticAmount;
        } else {
          aggregatedData.push({ ...channel });
        }
      });
    });
  }

  if (user === true) {
    overallChannelData = aggregatedData;
  } else {
    overallChannelData = ChannelData;
  }
  return (
    <myContext.Provider value={{ isAllOwners, setIsAllOwners}}>
      <>
        <div className="overallstatus">
          <OverAllStatusHeader setFilter={handleFilter} pipelineData = {handlePipelineData}/>

          <div className="overall-status-card-component">
            <ChannelCollection channelData={overallChannelData} />
          </div>
          <div className="overall-status-card-progress-component">
            {overallChannelData.length > 0 && (
              <OverallStatusCard
                amount={overallChannelData}
              />
            )}
            {overallChannelData.length > 0 && (
              <DashboardPipeline amount={overallChannelData} pipelineData={pipelineData} />
            )}
            {overallChannelData.length > 0 && user === false ? (
              <ChannelView amount={overallChannelData} />
            ) : (
              <UserView amount={ChannelData} /> //sent channelData onstead of overallChannelData it is because want username in userview component
            )}
          </div>
        </div>
      </>
    </myContext.Provider>
  );
};

export default OverallStatus;
