import axios from "axios";
import { sortAllOwnersApi } from "./sortAllOwnersApi";
import { userChannelApiUrl } from "../constants/apis";
export async function getOwnersChannelData(ownerId) {
  console.log("helo world");
  const response = await axios({
    method: "post",
    url: userChannelApiUrl, //get->ownerChannel
    data: { ownerId: ownerId },
  });
  console.log("channeldatalllll", response.data, ownerId);
  if (response.data) {
    let ownerData = sortAllOwnersApi(response.data);
    return ownerData;
  }
}
