import "./OverallStatusCard.scss";
import "../commonComponents/OverallProgressbar/OverallProgressbar.scss";
import ProgressBar from "../commonComponents/OverallProgressbar/OverallProgressbar";
import { sortAllOwnersApi } from "../../utility/sortAllOwnersApi.js";
import ChannelData from "../commonComponents/ImprovementChannelData/ImprovementChannelData";
import {
  numConversion,
  convertToTwoDecimals,
} from "../../utility/dataManipulation/DataConversion.js";

const OverallStatusCard = (props) => {
  let channelData = sortAllOwnersApi(props.amount);
  let totalTargetAmount = 0,
    totalCommitted = 0,
    totalGapToTarget = 0,
    totalProjected = 0,
    totalOptimistic = 0,
    totalCommittedDifference = 0,
    totalProjectedDifference = 0,
    totalOptimisticDifference = 0,
    totalCommittedPercentage = 0,
    totalProjectedPercentage = 0,
    totalOptimisticPercentage = 0;
  function validateData(value) {
    return value === null ? 0 : value;
  }
  channelData.forEach((element) => {
    if (!element.hasOwnProperty("channelName"))
      totalTargetAmount = validateData(element.totalTargetAmount);
    totalCommitted = validateData(element.totalCommitAmount);
    totalGapToTarget = validateData(element.totalGapToTarget);
    totalProjected = validateData(element.totalProjectAmount);
    totalOptimistic = validateData(element.totalOptimisticAmount);
    totalCommittedDifference = validateData(element.totalCommitDifference);
    totalCommittedPercentage = validateData(element.totalCommitPercentage);
    totalProjectedDifference = validateData(element.totalProjectDifference);
    totalProjectedPercentage = validateData(element.totalProjectPercentage);
    totalOptimisticDifference = validateData(element.totalOptimisticDifference);
    totalOptimisticPercentage = validateData(element.totalOptimisticPercentage);
  });
  let avgDealSize = numConversion(3000000);
  let dealsForTarget = totalGapToTarget<0 ?0:totalGapToTarget / 3000000>0 && totalGapToTarget / 3000000<0 ?1:parseInt(totalGapToTarget / 3000000); //116;
  let target = {
    display: "Target",
    original: totalTargetAmount,
    displayValue: `${Math.round((totalTargetAmount / 10000000) * 100) / 100}`,
    displayByCommaValue:
      totalTargetAmount &&
      totalTargetAmount.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
      }),
  };
  let weakOnweakStatus = [
    {
      originalDifference: totalCommittedDifference,
      originalPercentage: totalCommittedPercentage, //"5",
      difference: numConversion(totalCommittedDifference),
      percentage: convertToTwoDecimals(totalCommittedPercentage),
      displayByCommaValue:
        totalCommittedDifference &&
        totalCommittedDifference.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      originalDifference: totalProjectedDifference,
      originalPercentage: totalProjectedPercentage,
      difference: numConversion(totalProjectedDifference),
      percentage: convertToTwoDecimals(totalProjectedPercentage),
      displayByCommaValue:
        totalProjectedDifference &&
        totalProjectedDifference.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      originalDifference: totalOptimisticDifference,
      originalPercentage: totalOptimisticPercentage,
      difference: numConversion(totalOptimisticDifference),
      percentage: convertToTwoDecimals(totalOptimisticPercentage),
      displayByCommaValue:
        totalOptimisticDifference &&
        totalOptimisticDifference.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
  ];

  let channelOverAllArray = [
    {
      display: "Gap to Target",
      original: totalGapToTarget,
      displayValue: totalGapToTarget<0?"0*":`${
        Math.round((totalGapToTarget / 10000000) * 100) / 100
      }`,
      displayByCommaValue:
        totalGapToTarget &&
        totalGapToTarget.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      display: "Commited",
      original: totalCommitted,
      displayValue: `${Math.round((totalCommitted / 10000000) * 100) / 100}`,
      displayByCommaValue:
        totalCommitted &&
        totalCommitted.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      display: "Projected",
      original: totalProjected,
      displayValue: `${Math.round((totalProjected / 10000000) * 100) / 100}`,
      displayByCommaValue:
        totalProjected &&
        totalProjected.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      display: "Optimistic",
      original: totalOptimistic,
      displayValue: `${Math.round((totalOptimistic / 10000000) * 100) / 100}`,
      displayByCommaValue:
        totalOptimistic &&
        totalOptimistic.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
  ];
  const todayDate = new Date();
  const lastDate = new Date("March 31, 2024 24:00:00");
  const leftDays =
    Math.floor(
      (lastDate.getTime() - todayDate.getTime()) / (1000 * 24 * 3600)
    ) + " Days";

  return (
    <>
      <div className="progress-bar-and-reminder">
        <ProgressBar amount={channelData} />
      </div>
      <div className="reminder-buttom">
        <div className="channel-data1">
          <ChannelData
            className="overall-status-container"
            channelOverAllArray={channelOverAllArray}
            target={target}
            weakOnweakStatus={weakOnweakStatus}
            remainingDays={true}
            avgDealSize={avgDealSize}
            dealsForTarget={dealsForTarget}
          />
        </div>
      </div>
      <div className="horizonatal-line"></div>
    </>
  );
};
export default OverallStatusCard;
