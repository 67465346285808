import React from "react";
import "./PerformanceProgressbar.scss"; // import the CSS styles
import "../Performance/Performance.scss";
function SemicircularProgressBar({ value }) {
  return (
    <>
      <div
        className="semi-donut"
        style={{ "--percentage": `${value}`, "--fill": "#DB4833" }}
      >
        <div className="circular-progress-bar-content">
          <div className="progress-amount">
            <div className="progress-bar-amount">{value}</div>
            <div className="percentile">%</div>
          </div>
          <div className="progress-bar-description">Target Achieved</div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default SemicircularProgressBar;
