export function numConversion(value) {
  console.log(value, "numCon");
  if (value === null || value === undefined) {
    return 0;
  }
  let absValue = Math.abs(value),
    res = value;
  if (absValue >= 10000000) {
    res = absValue / 10000000;
    res = Number.isInteger(res)
      ? `${res} Cr`
      : `${(absValue / 10000000).toFixed(1)} Cr`;
  } else if (absValue >= 100000) {
    res = absValue / 100000;
    res = Number.isInteger(res)
      ? `${res} L`
      : `${(absValue / 100000).toFixed(1)} L`;
  } else if (absValue >= 1000) {
    res = absValue / 1000;
    res = Number.isInteger(res)
      ? `${res} K`
      : `${(absValue / 1000).toFixed(1)} K`;
  } else
    res = Number.isInteger(absValue) ? `${absValue}` : `${absValue.toFixed(1)}`;
  if (value >= 0) {
    return res;
  } else {
    return `-${res}`;
  }
}
export function convertToTwoDecimals(value) {
  console.log(value, "decimals");
  // if(value!==undefined || value!==null)
  // {
  //   return Number.isInteger(value)?`${value}%`:`${value.toFixed(1)}%`;
  // }
  // else
  // {
  //   return 'null';
  // }
  if (value === null || value === undefined) return "0%";
  return Number.isInteger(value) ? `${value}%` : `${value.toFixed(1)}%`;
}
