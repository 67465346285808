import ChannelData from "../commonComponents/ImprovementChannelData/ImprovementChannelData";
import SemicircularProgressBar from "../PerformanceProgressbar/PerformanceProgressbar.js";
import "./Performance.scss";
const Performance = (props) => {
  const target_amount =
    props.overAllStatus[1].displayValue === 0
      ? 0
      : (props.overAllStatus[1].original * 100) / props.target.original;
  const total_target_amount = Number.isInteger(target_amount)
    ? target_amount
    : target_amount.toFixed(2); // round off to 2 digits

  return (
    <>
      <div className="performance">

        <SemicircularProgressBar value={total_target_amount} />
        <div className="performance-card-data">
          <ChannelData
            className="performance-container"
            channelOverAllArray={props.overAllStatus}
            weakOnweakStatus={props.weakOnweakStatus}
            target={props.target}
          />
        </div>
      </div>
    </>
  );
};
export default Performance;
