import axios from "axios";
import {
  dashboardApiUrl,
  individualTargetContributionApiUrl,
} from "../constants/apis";

export async function performanceApi() {
  try {
    const response = await axios({
      method: "get",
      url: dashboardApiUrl,
    });
    const data = response.data;
    console.log("data in perf", response.data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function individualTargetContributionApi(channelId, quarterId) {
  try {
    const response = await axios({
      method: "post",
      url: individualTargetContributionApiUrl,
      data: { channelId: channelId, quarterId: quarterId },
    });
    const data = response.data;
    return data;
  } catch (error) {}
}
