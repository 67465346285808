//
// export default DropdownFilter;
import React, { useState, useRef, useEffect } from "react";
import dropdownArrow from "../../../assets/images/dropdownArrow.svg";
import "./DropdownFilter.scss";
import { MenuItem } from "@mui/material";
import selected from "../../../assets/images/selected.svg";
const DropdownFilter = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.label);
  const [disable, setDisable] = useState(false);
  const [value, setValue] = React.useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add event listener to window when the dropdown is open
    if (isOpen) {
      window.addEventListener("click", handleOutsideClick);
    }

    // Clean up event listener when the dropdown is closed
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  const handleOutsideClick = (event) => {
    // Close the dropdown if clicked outside of it
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      event.target.className != "dropdown-list"
    ) {
      setIsOpen(false);
    }
  };

  const handleChange = (value) => {
    setSelectedValue(value.name);
    setIsOpen(false);
    setValue(value.id);
    if (props.id === 0 && value.id === 1) {
      setDisable(true);
    } else {
      setDisable(false);
    }

    props.handleFilter(value.id, props.id);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-dropdown">
      <div
        ref={dropdownRef}
        className="dropdown-header"
        onClick={toggleDropdown}
      >
        <div className="dropdown-label-icon">
          <img src={props.icon} alt="Setting" className="dropdown-label-icon" />
          <span className="dropdown-value">
            {selectedValue ? selectedValue : props.label}
          </span>
        </div>
        <div>
          <img
            className="dropdown-trigger-image"
            src={dropdownArrow}
            alt="dropdown-trigger icon"
          />
        </div>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {props.options.map((data, index) => (
            <MenuItem
              className={
                props.disabledOptions.includes(data) || data.name === "User"
                  ? "disable-item"
                  : selectedValue === data.name
                  ? "selected-item"
                  : index < props.options.length - 1
                  ? "dropdown-item"
                  : "last-dropdown-item"
              }
              key={data.id}
              value={data.id}
              disabled={props.disabledOptions.includes(data)}
              onClick={
                data.name != "User" ? () => handleChange(data) : () => {}
              }
            >
              <div className="filter-option">{data.name}</div>
              {selectedValue === data.name && (
                <img className="selected-image" src={selected} alt="" />
              )}
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
