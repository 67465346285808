export const channelsColumns = [
  {
    field: "channelName",
    headerName: "Channel",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Channel</strong>,
  },
  {
    field: "toRecover",
    headerName: "To Recover",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>To Recover</strong>,
  },
  {
    field: "deviationTarget",
    headerName: "Deviation to Target",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Deviation to Target</strong>,
  },
  {
    field: "gapToTarget",
    headerName: "Gap to Target",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Gap to Target</strong>,
  },
  {
    field: "percentAchieved",
    headerName: "% of Achieved Target",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>% of Achieved Target</strong>,
  },
  {
    field: "achievedTarget",
    headerName: "Achieved Target",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Achieved Target</strong>,
  },
  {
    field: "finalTarget",
    headerName: "Final Target ",
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Final Target </strong>,
  },

  //   {
  //     field: "fullName",
  //     headerName: "Full name",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     width: 160,
  //     // valueGetter: (params: GridValueGetterParams) =>
  //     //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  //   },
];

export const overviewColumns = [
  {
    field: "targetAmount",
    headerName: "Overall Target",
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Overall Target </strong>,
  },
  {
    field: "gapToTarget",
    headerName: "Gap to Target",
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderHeader: () => <strong>Gap to Target</strong>,
  },
];

export const pipelineColumns = [
  {
    field: "id",
    headerName: "PipeLine",
    width: 170,
    renderHeader: () => <strong>PipeLine </strong>,
  },
  {
    field: "target",
    headerName: "Target",
    width: 130,
    renderHeader: () => <strong>Target </strong>,
  },
  {
    field: "accomplished",
    headerName: "Accomplished",
    width: 130,
    renderHeader: () => <strong>Accomplished </strong>,
  },
  {
    field: "deals",
    headerName: "Deals",
    width: 130,
    renderHeader: () => <strong>Deals</strong>,
  },
  {
    field: "pending",
    headerName: "Pending",
    width: 130,
    renderHeader: () => <strong>Pending</strong>,
  },
];

export const individualPipelineColumns = [
  ...pipelineColumns,
  {
    field: "leadsText",
    headerName: "Number of Leads/Meetings Required",
    width: 200,
    renderHeader: () => <strong>Leads/Meetings Required</strong>,
  },
];
