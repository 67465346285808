import { userdatalist } from "../constants/dropdown_data";

export default async function userviewApi() {
  console.log("userApi invoked");
  // console.log(userdatalist, "userView response===>>>");

  const userviewnamelist = ["Saikamal", "Viswa", "Shashank", "Aditi","Sumit"];
  console.log(userdatalist, "userView response===>>>");
  // const userdatalist = response.data;
  const filteredUserviewData = userdatalist.filter((userData) =>
    userviewnamelist.includes(userData.ownerName)
  );
  console.log(filteredUserviewData, "userviewfiltereddata in api");
  return filteredUserviewData;
  //  const filteredUserviewData=
  // let response = await axios({
  //   method: "get",
  //   url: userViewApiUrl,
  //   data: {
  //     quarterId: "1",
  //     channelId: "1",
  //   },
  // });
  // console.log(userdatalist, "userView response===>>>");
  // // const userdatalist = response.data;
  // const filteredUserviewData = userdatalist.filter((userData) =>
  //   userviewnamelist.includes(userData.ownerName)
  // );
  // console.log(filteredUserviewData, "userviewfiltereddata in api");
  // return filteredUserviewData;
}
