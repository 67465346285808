import { useEffect, useState } from "react";
import { performanceApi } from "../../utility/performanceBarApi.js";
import Dashboard from "../Dashboard/Dashboard";
import SalesDashboard from "../SalesDashboard/SalesDashboard.js";

const DashboardScreen = ({
  welcome,
  salesDashboard,
  financeDashboard,
  userRole,
}) => {
  let [performanceData, setperformanceData] = useState({});

  useEffect(() => {
    async function getData() {
      const response = await performanceApi();
      setperformanceData(response); //set dashboard overall performance data
    }
    getData();
  }, []);

  return (
    <>
      {performanceData === undefined ? (
        <p></p>
      ) : Object.keys(performanceData).length !== 0 ? (
        <Dashboard
          data={performanceData}
          welcome={welcome}
          salesDashboard={salesDashboard}
          financeDashboard={financeDashboard}
          userRole={userRole}
        />
      ) : (
        <p></p>
      )}
      {/* <SalesDashboard /> */}
    </>
  );
};
export default DashboardScreen;
