import axios from "axios";
import { oAuthUsersApiUrl } from "../constants/apis";

export async function isUserAuthorized(email) {
  try {
    console.log("email sent",email)
    const response = await axios({
      method: "post",
      data:{emailId:email},
      url: oAuthUsersApiUrl,
      // headers: {
      //   Authorization: `Bearer ${jwtToken}`,
      // },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}