import React from "react";
import ChannelBox from "../commonComponents/ChannelBox/ChannelBox";
import "../ChannelCollection/ChannelCollection.scss";
const channelColors = [
  "outbond",
  "inbond",
  "sales",
  "network",
  "mining",
  "business",
  "partner",
  "marketingInbound",
];
const ChannelCollection = ({ channelData }) => {
  channelData=channelData.filter((item)=>item.hasOwnProperty('channelName'));
 return(
  <div className="channel-collection-right">
  {channelData.map((data, index) => (
    <ChannelBox color={channelColors[index]} name={data.channelName} />
  ))}
</div>
 )
};

export default ChannelCollection;
