import React, { useState } from "react";
import { Progress } from "rsuite";
import { KeyboardArrowDown } from "@mui/icons-material";
import { IconButton, ThemeProvider, Tooltip, createTheme } from "@mui/material";
import "./IndividualVerticalBars.scss";

const IndividualVerticalBars = (props) => {
  const { channelData } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const barsToShow = 3;
  const [showPrevious, setShowPrevious] = useState(false);

  const crore = 10000000;
  const dealsAmount = 3000000;
  let adjustedBarsToShow = barsToShow;

  const theme = createTheme();
  const maxIterations = Math.ceil(channelData.length / barsToShow);
  const handleArrowClick = () => {
    if (showPrevious) {
      setCurrentIndex(currentIndex - barsToShow);
      adjustedBarsToShow = barsToShow;
    } else {
      const nextIndex = currentIndex + barsToShow;
      if (nextIndex < maxIterations * barsToShow) {
        setCurrentIndex(nextIndex);
        adjustedBarsToShow = barsToShow;
      } else {
        adjustedBarsToShow = channelData.length - nextIndex;
      }
    }
    setShowPrevious(!showPrevious);
  };

  const channelColors = {
    "Account Mining": "#76C894",
    Outbound: "#611FED",
    "Marketing Inbound": "#54A6C0",
    "CXO Sales": "#CC2877",
    "Network Inbound": "#C8DB57",
    Partner: "#76CAFD",
    "Existing Business": "#FF9518",
  };

  const donutData = {
    datasets: [
      {
        backgroundColor: Object.values(channelColors),
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="individualProgressBarMianContainer">
        {channelData
          ?.slice(currentIndex, currentIndex + adjustedBarsToShow)
          .map((channel, index) => {
           
            if (channel.channelName) {
              const committedAmount = channel.committedAmount || 0;
              const achivedTargetAmount = channel.targetAmount;
              const IndividualGapToTarget =
                channel.targetAmount - committedAmount;
              const nonNegativeGapTarget =
                IndividualGapToTarget < 0 ? 0.0 : IndividualGapToTarget;
              const totalDeals = IndividualGapToTarget / dealsAmount;
              const nonNegativeTotalDeals = totalDeals < 0 ? 0.0 : totalDeals;
              let percent = (
                ((parseFloat(channel.committedAmount) || 0) /
                  channel.targetAmount) *
                100
              ).toFixed(2);
              percent = Math.min(percent, 100);
              const totalPercent = (
                ((parseFloat(channel.committedAmount) || 0) /
                  channel.targetAmount) *
                100
              ).toFixed(2);
              const IndividualDeviationToTarget =
                (IndividualGapToTarget / channel.targetAmount) * 100;
              const nonNegativeDeviationTarget =
                IndividualDeviationToTarget < 0
                  ? 0.0
                  : IndividualDeviationToTarget;

              return (
                <div className="progressBarMainSection">
                  <div className="progressBarSection">
                    <div className="progressBar">
                      <Progress.Line
                        percent={percent}
                        strokeWidth={30}
                        strokeColor={channelColors[channel.channelName]}
                        trailColor="#d1d0cf"
                        strokeLinecap="square"
                        style={{ height: "100px", width: "30%" }}
                      />
                    </div>
                    <div className="progressBarSideContent">
                      <div className="dealsSection">
                        <div className="dealsNumbers">
                          {Math.round(nonNegativeTotalDeals)}
                          <span>Deals</span>
                        </div>
                        <div className="dealContent">To Recover</div>
                      </div>
                      <div className="deviationTargetSec">
                        <div className="deviationNumbers">
                          {/* {isNaN(IndividualDeviationToTarget) ? "0 %" : `${IndividualDeviationToTarget} %`}{" "} */}
                          {nonNegativeDeviationTarget.toFixed(2)} %
                          <br />
                        </div>
                        <div className="deviationTargetName">
                          Deviation to Target
                        </div>
                      </div>
                      <div className="gapTargetSec">
                        <Tooltip
                          title={`${channel.targetAmount - committedAmount}`}
                          arrow
                          placement="bottom"
                        >
                          <div className="gapTargetNumbers">
                            {/* {isNaN((IndividualGapToTarget / crore).toFixed(2))
                            ? "0"
                            : `${(IndividualGapToTarget / crore).toFixed(2)}`} */}
                            {(nonNegativeGapTarget / crore).toFixed(2)} Cr{" "}
                            <br />
                          </div>
                        </Tooltip>
                        <div className="gapTargetname">Gap to Target</div>
                      </div>
                    </div>
                  </div>

                  <div className="progressBarChannelsSection">
                    <div className="chanelsSection">
                      <div
                        className="roundedBorder"
                        style={{
                          backgroundColor: channelColors[channel.channelName],
                        }}
                      >
                        {donutData.datasets.backgroundColor}
                      </div>
                      <div className="channelsName">
                        {channel.channelName}{" "}
                        {/* {`(${isNaN(orginalGraph) ? "0.0" : orginalGraph}%)`} */}
                        ({totalPercent}%)
                      </div>
                    </div>
                    <div className="achivedSection">
                      <Tooltip
                        title={`${committedAmount}`}
                        arrow
                        placement="bottom"
                      >
                        <span style={{ paddingRight: "5px" }}>
                          {/* {isNaN(IndividualDeviationToTarget)
                          ? "0"
                          : (channel.committedAmount / crore).toFixed(2)} */}
                          {(committedAmount / crore).toFixed(2)} Cr
                        </span>
                      </Tooltip>
                      /
                      <Tooltip
                        title={`${channel.targetAmount}`}
                        arrow
                        placement="bottom"
                      >
                        <span style={{ paddingLeft: "5px" }}>
                          {/* {isNaN(IndividualDeviationToTarget)
                          ? "0"
                          : (channel.targetAmount / crore).toFixed(2)} */}
                          {(achivedTargetAmount / crore).toFixed(2)} Cr
                        </span>
                      </Tooltip>
                      <div className="achivedText">Achieved</div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
      {channelData?.length > 3 ? (
        <div className="individualShowMoreButton">
          <IconButton
            onClick={handleArrowClick}
            size="large"
            style={{ fontSize: "32px" }}
          >
            <KeyboardArrowDown
              style={{
                transform: showPrevious ? "rotate(90deg)" : "rotate(270deg)",
              }}
              fontSize="large"
            />
          </IconButton>
        </div>
      ) : null}
    </ThemeProvider>
  );
};

export default IndividualVerticalBars;
