import axios from "axios";
import { sortAllOwnersApi } from "./sortAllOwnersApi";
import { channelViewApiUrl } from "../constants/apis";

export async function allOwners() {
  try {
    const response = await axios({
      method: "get",
      url: channelViewApiUrl,
    });
  console.log("allOwners",response.data)
    if (response.data.length) {
      let data2 = await sortAllOwnersApi(response.data);
      return data2;
    }
  } catch (error) {
    console.log(error);
  }
}
