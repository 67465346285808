import { useEffect, useState } from "react";
import { allOwners } from "../../utility/allOwners";
import { pipelineApi, ownerPipelineApi } from "../../utility//pipelineApi";
import { pipelineApiUrl } from "../../constants/apis";
import { DropDownSelect } from "../DropDownSelect/DropDownSelect.component";
import { overviewColumns } from "./constants";
import "./SalesDashboard.scss";
import {
  individualTargetContributionApi,
  performanceApi,
} from "../../utility/performanceBarApi";
import { quartersApi } from "../../utility/quartersApi";
import { channelDataMassageFn, roundOffTwoDecimals } from "./utils";
import { userListApi } from "../../utility/userListApi";
import DonutChart from "../DonutChart/DonutChart";
import ProgressBar from "../ProgressBar/ProgressBar";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import calenderIcon from "../../assets/images/calendar.svg";
import OverallSalesBar from "../OverallSalesBar/OverallSalesBar";
import IndividualVerticalBars from "../IndividualVerticalBars/IndividualVerticalBars";
import IndividualDonutChart from "../IndividualDonutChart/IndividualDonutChart";
import axios from "axios";
import IndividualHorizontalBar from "../IndividualHorizontalBar/IndividualHorizontalBar";
import IndividualOverallPipelineData from "../IndividualOverallPipelineData/IndividualOverallPipelineData";

const SalesDashboard = (props) => {
  const quaterFilters = [
    { label: "FY 2023-2024", value: 0 },
    { label: "Q1 2023", value: 1 },
    { label: "Q2 2023", value: 2 },
    { label: "Q3 2023", value: 3 },
    { label: "Q4 2024", value: 4 },
  ];
  const channelsFilter = [
    { label: "All", value: 0 },
    { label: "Marketing Inbound", value: "1" },
    { label: "Outbound", value: "2" },
    { label: "Existing Bussiness", value: "3" },
    { label: "Account Mining", value: "4" },
    { label: "CXO Sales", value: "5" },
    { label: "Network Inbound", value: "6" },
    { label: "Partner", value: "7" },
  ];
  const calc = [1.5, 2, 3, 4];
  // const [selectedChannel, setSelectedChannel] = useState("All");

  const [selectedChannel, setSelectedChannel] = useState(null);

  const handleChannelChange = (channelValue) => {
    setSelectedChannel(channelValue);
  };

  const [selectedQuarter, setSelectedQuarter] = useState(0);
  const [target, setTarget] = useState(0);
  const [channelsData, setChannelsData] = useState([]);
  const [overviewData, setOverviewData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [overviewColumnsState, setOverviewColumnsState] = useState([]);
  const [individualOverviewColumnsState, setIndividualOverviewColumnsState] =
    useState([]);

  const [channelDataForMembers, setChannelDataForMembers] = useState([]);
  const dealAmount = 3000000;
  const crore = 10000000;
  const [ownerId, setOwnerId] = useState(0);

  // const [quarterId, setQuarterId] = useState(0);
  const [individualData, setIndividualData] = useState([]);
  const getSalesChannelsData = async () => {
    let response = [];
    if (selectedQuarter == 0) {
      response = await allOwners();
    } else {
      response = await quartersApi(0, selectedQuarter);
    }
    const data = channelDataMassageFn(response, dealAmount, crore);
    if (data.length) setChannelsData(data.filter((each) => each.channelName));
  };

  const getSalesOverviewData = async () => {
    let response = [];

    const dashboardResponse = await performanceApi();
    setTarget(dashboardResponse.targetAmount || 0);
    if (selectedQuarter == 0) {
      response = await allOwners();
    } else {
      response = await quartersApi(0, selectedQuarter);
    }
    let columnsConfig = overviewColumns;

    if (response?.length) {
      columnsConfig = [
        ...columnsConfig,
        ...response.map((each, index) => {
          return {
            field: `${each.channelName}1`,
            headerName: each.channelName,
            width: 148,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => <strong>{each.channelName}</strong>,
          };
        }),
      ];
    }
    let individualConfig = columnsConfig;
    individualConfig.splice(2, 0, {
      field: "committedPercentage",
      headerName: "Contribution to Target",
      width: 170,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => <strong>Contribution to Target </strong>,
    });
    setOverviewColumnsState(
      columnsConfig.filter(
        (each) => each.headerName && each.field !== "committedPercentage"
      )
    );
    setIndividualOverviewColumnsState(
      individualConfig.filter((each) => each.headerName)
    );
    let obj = {};

    let targetAmount = 0,
      gapToTarget = 0;
    response.forEach((element, index) => {
      targetAmount = targetAmount + Number(element.targetAmount || 0);
      gapToTarget = gapToTarget + Number(element.gapToTarget || 0);

      if (index !== response.length - 1)
        obj = {
          ...obj,
          [`${element.channelName}1`]: `${roundOffTwoDecimals(
            Number(element.committedAmount || 0) / crore
          )} Cr`,
        };
    });

    obj = {
      ...obj,
      targetAmount: `${roundOffTwoDecimals(targetAmount / crore)} Cr`,
      gapToTarget: `${roundOffTwoDecimals(gapToTarget / crore)} Cr`,
      id: 0,
    };
    setOverviewData([obj]);
  };
  const getPipelineData = async () => {
    let response = await axios({
      method: "post",
      url: pipelineApiUrl,
      data: { ownerId: ownerId, quarterId: selectedQuarter },
    });

    const channelSequence = [
      "SQL (Sales Qualified)",
      "Requirements Analysis",
      "Proposal/Price Quote",
      "Final Consideration",
    ];

    let pipelineData = response.data;
    let filteredObject = pipelineData.filter((item) => {
      return channelSequence.includes(item.pipeline);
    });

    const pipelineResData = filteredObject.reverse();
    const pipe = [
      ...pipelineResData.map((each, index) => {
        return {
          id: each.pipeline,
          index: index,
          target: `${roundOffTwoDecimals(Number(target) / crore)} Cr`,
          accomplished: each.sum,
          deals: each.count,
          pending: `${roundOffTwoDecimals(
            Number(target - each.sum) / crore
          )} Cr`,
        };
      }),
    ];
    const pipeData = [
      ...pipe.map((each) => {
        if (each.id === "Proposal/Price Quote") {
          return {
            ...each,
            target: `${roundOffTwoDecimals(
              Number(target * calc[1]) / crore
            )} Cr`,
            pending: `${roundOffTwoDecimals(
              Number(target * calc[1] - each.accomplished) / crore
            )} Cr`,
          };
        } else if (each.id === "Requirements Analysis") {
          return {
            ...each,
            target: `${roundOffTwoDecimals(
              Number(target * calc[2]) / crore
            )} Cr`,
            pending: `${roundOffTwoDecimals(
              Number(target * calc[2] - each.accomplished) / crore
            )} Cr`,
          };
        } else if (each.id === "Final Consideration") {
          return {
            ...each,
            target: `${roundOffTwoDecimals(
              Number(target * calc[0]) / crore
            )} Cr`,
            pending: `${roundOffTwoDecimals(
              Number(target * calc[0] - each.accomplished) / crore
            )} Cr`,
          };
        } else if (each.id === "SQL (Sales Qualified)") {
          return {
            ...each,
            target: `${roundOffTwoDecimals(
              Number(target * calc[3]) / crore
            )} Cr`,
            pending: `${roundOffTwoDecimals(
              Number(target * calc[3] - each.accomplished) / crore
            )} Cr`,
          };
        } else {
          return each;
        }
      }),
    ];
    setPipelineData(pipeData);
  };

  const getOwnersPipelineData = async () => {
    const response = await ownerPipelineApi(ownerId, selectedQuarter);
    setChannelDataForMembers(response);
  };

  const getIndividualPipelineData = (channelData) => {
    let data = [];
    data = [
      ...channelData.map((each, index) => {
        return {
          id: each.channelName,
          index: index,
          target: `${roundOffTwoDecimals(
            Number(each.committedAmount) / crore
          )} Cr`,
          deals: 0,
          pending: 0,
          leadsRequired: 0,
          meetingsRequired: 0,
        };
      }),
    ];
    return data;
  };

  useEffect(() => {
    getSalesChannelsData();
    getSalesOverviewData();
    getPipelineData();
    // setChannelId(0)
  }, [selectedQuarter / target]);

  useEffect(() => {
    getSalesChannelsData();
    getSalesOverviewData();
    getPipelineData();
    getOwnersPipelineData();
  }, []);

  useEffect(() => {
    getOwnersPipelineData();
  }, [ownerId]);

  const getIndividualChannelsData = async () => {
    setIndividualData([]);

    const response = await individualTargetContributionApi(
      ownerId,
      selectedQuarter
    );
    const ownerList = await userListApi();
    let arr = [];

    Object.keys(response.contributers).forEach((item) => {
      let obj = {};
      let targetAmount = 0,
        gapToTarget = 0,
        committedAmount = 0;
      const ownerData = ownerList.find((each) => each.id == item);
      response.contributers[item].forEach((each, index) => {
        targetAmount = targetAmount + Number(each.targetAmount);
        committedAmount =
          committedAmount +
          Number(each.commitedAmount || each.committedAmount || 0);
        obj = {
          ...obj,
          [`${each.channelName}1`]: roundOffTwoDecimals(
            Number(each.committedAmount || each.commitedAmount || 0) / crore
          ),
        };
      });
      gapToTarget = targetAmount - committedAmount;
      arr.push({
        owner: ownerData,
        data: channelDataMassageFn(
          response.contributers[item],
          dealAmount,
          crore
        ),
        overviewData: [
          {
            targetAmount: `${roundOffTwoDecimals(targetAmount / crore)} Cr`,
            gapToTarget: `${roundOffTwoDecimals(gapToTarget / crore)} Cr`,
            committedPercentage: `${roundOffTwoDecimals(
              ((targetAmount - gapToTarget) / targetAmount) * 100
            )} %`,
            ...obj,
            id: "1",
          },
        ],
      });
    });
    setIndividualData(arr);
  };
  useEffect(() => {
    getIndividualChannelsData();
    getOwnersPipelineData();
    // setOwnerId(0)
  }, [selectedQuarter, ownerId]);

  const [overAllGapToTarget, setOverAllGapToTarget] = useState(0);
  const [overAllTarget, setOverAllTarget] = useState(0);

  const handleRemainingAmount = (amount) => {
    setOverAllGapToTarget(amount);
  };

  const handleOverAllAmount = (amount) => {
    setOverAllTarget(amount);
  };

  const handleOwnerData = (ownerData) => {
    if (ownerId == 0) {
      return ownerData.data
        .filter((eachOwner) => eachOwner.ownerId == ownerData.owner.id)
        .map((eachOwner) => ({
          channelId: eachOwner.channelId,
          channelName: eachOwner.channelName,
        }));
    } else {
      return channelsFilter
        .filter((item) => item.value === ownerId)
        .map((item) => ({
          channelId: item.value,
          channelName: item.label,
        }));
    }
  };

  const handleOptionSelect = (value) => {
    setSelectedChannel(value);
    setOwnerId(value);
  };

  useEffect(() => {
  }, [selectedChannel]);

  return (
    <>
      <div className="salesDashboardWrapper">
        <div className="heading">Sales Dashboard</div>

        <div className="filterWrapper">
          <div className="salesTitle">
            Hi Team, <br></br>
            <span>Please check your overall sales health</span>
          </div>
          <div className="qutersDropdownSection">
            <img
              src={calenderIcon}
              alt="idea icon"
              style={{ marginTop: "-5px" }}
            />
            <DropDownSelect
              dropDownOptions={quaterFilters}
              selectedOptionCallBack={setSelectedQuarter}
              dropDownLabel={""}
              className="custom-dropdown"
            />
          </div>
        </div>
        <div className="overallSalesSection">
          <DonutChart
            channelsData={channelsData}
            gapToTargetAmount={overAllGapToTarget}
            overAllTarget={overAllTarget}
          />
          <div className="progressBarGroup">
            <ProgressBar
              channelData={channelsData}
              overAllGapToTarget={handleRemainingAmount}
              overAllTarget={handleOverAllAmount}
            />
          </div>
        </div>

        <div className="bottomBorder"></div>
        {/* <div className="horizontalHeaderChannels"> */}
        <h2 className="horizontalBarSectionHeader">Overall Pipeline View</h2>
        {/* <DropDownSelect
              dropDownOptions={channelsFilter}
              selectedOptionCallBack={handleOptionSelect}
              dropDownLabel={"Channels"}
              defaultValue={"All"} 
            /> */}
        {/* </div> */}
        <div className="">
          {pipelineData?.length && (
            <>
              <HorizontalBar
                pipelineData={pipelineData}
                gapToTargetAmount={overAllGapToTarget}
                overallChannelsData={channelsData}
              />
            </>
          )}
        </div>
        <div className="bottomBorder"></div>
        <div>
          <div className="filterWrapper">
            <div className="heading">Individual Targets and Contributions</div>
            <DropDownSelect
              dropDownOptions={channelsFilter}
              selectedOptionCallBack={setOwnerId}
              dropDownLabel={"Channels"}
            />
          </div>
          {individualData.map((ownerData, ownerIndex) => {
            if (ownerData?.owner.name !== "None") {
              return (
                <div className="individualSalesMainSection">
                  <div className="individualOwnerName">
                    {ownerData.owner.name}
                  </div>
                  <div className="overallIndividualSalesSection">
                    <IndividualDonutChart channelsData={ownerData} />
                    <div className="progressBarGroup">
                      <IndividualVerticalBars channelData={ownerData.data} />
                    </div>
                  </div>
                  {/* <OverallSalesBar channelsData={ownerData} /> */}
                  <div className="bottomBorderIndividualSales"></div>

                  {/* {selectedChannel === "All" ? (
                      <>
                        <h2 className="horizontalBarSectionHeader">Overall Pipeline View</h2>
                        <div className="IndividualHorizontalContainer">
                        {pipelineData?.length && (
                            <>
                            <IndividualOverallPipelineData 
                              data={ownerData} 
                              pipelineData={pipelineData}
                            />
                            </>
                          )}
                        </div>
                      </>
                    ) : null} */}
                  {/* <div class="bottomBorder"></div> */}
                  <div className="horizontalBarMain">
                    <IndividualHorizontalBar
                      individualBarData={
                        channelDataForMembers[ownerData.owner.id]
                      }
                      selectedChannel={ownerId}
                      keys={handleOwnerData(ownerData)}
                      individualpipelineData={ownerData.data}
                    />
                    {(
                      channelDataForMembers,
                      ownerData,
                      ""
                    )}
                  </div>
                </div>
              );
            }
            return null;
          })}
          {/* <div className="HorizontalGrayBar"></div> */}
        </div>
      </div>
    </>
  );
};
export default SalesDashboard;
