import HorizotalProgressBar from "../HorizontalProgressBar/HorizontalProgressBar.js";
import "./ChannelViewCard.scss";
import ChannelViewDetailsCard from "../ChannelViewDetailsCard/ChannelViewDetailsCard.js";
import ChannelData from "../ImprovementChannelData/ImprovementChannelData.js";
import {
  numConversion,
  convertToTwoDecimals,
} from "../../../utility/dataManipulation/DataConversion.js";
const ChannelViewCard = (props) => {
  const Target =
    Math.round((props.original.targetAmount / 10000000) * 100) / 100;
  const Gap_To_Target =
    Math.round((props.original.gapToTarget / 10000000) * 100) / 100;
  const Committed =
    Math.round((props.original.committedAmount / 10000000) * 100) / 100;
  const Projected =
    Math.round((props.original.projectedAmount / 10000000) * 100) / 100;
  const Optimistic =
    Math.round((props.original.optimisticAmount / 10000000) * 100) / 100;
  const committedValue = props.original.committedAmount === 0 ? 0 : (props.original.committedAmount * 100) / props.original.targetAmount;
  const committedPercentage = Number.isInteger(committedValue)?committedValue:committedValue.toFixed(2);
  // Math.round(committedValue * 100) / 100;
  const optimisticValue = props.original.optimisticAmount === 0 ? 0 : (props.original.optimisticAmount * 100) / props.original.targetAmount;
  const optimisticPercentage = Number.isInteger(optimisticValue)?optimisticValue:optimisticValue.toFixed(2);
  // Math.round(optimisticValue * 100) / 100;
  const projectedValue = props.original.projectedAmount === 0 ? 0 : (props.original.projectedAmount * 100) / props.original.targetAmount;
  const projectedPercentage = Number.isInteger(projectedValue)?projectedValue:projectedValue.toFixed(2);
  // Math.round(projectedValue * 100) / 100;
  const originalDataFormatted = (originalData) => {
    return (
      originalData &&
      originalData.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
      })
    );
  };
  const channelOverAllArray = [
    {
      display: "Gap to the Target",
      displayValue: Committed > Target ? "0*" : Gap_To_Target,
      original: props.original.gapToTarget,
      displayByCommaValue: originalDataFormatted(props.original.gapToTarget),
    },
    {
      display: "Commited",
      displayValue: Committed,
      original: props.original.committedAmount,
      displayByCommaValue: originalDataFormatted(
        props.original.committedAmount
      ),
    },
    {
      display: "Projected",
      displayValue: Projected,
      original: props.original.projectedAmount,
      displayByCommaValue: originalDataFormatted(
        props.original.projectedAmount
      ),
    },
    {
      display: "Optimistic",
      displayValue: Optimistic,
      original: props.original.optimisticAmount,
      displayByCommaValue: originalDataFormatted(
        props.original.optimisticAmount
      ),
    },
  ];
  const weakOnweakStatus = [
    {
      originalDifference: props.original.committedDifference,
      originalPercentage: props.original.committedPercentage,
      difference: numConversion(props.original.committedDifference), //converts number to currency
      percentage: convertToTwoDecimals(props.original.committedPercentage),
      displayByCommaValue: originalDataFormatted(
        props.original.committedDifference
      ),
    },
    {
      originalDifference: props.original.projectedDifference,
      originalPercentage: props.original.projectedPercentage,
      difference: numConversion(props.original.projectedDifference),
      percentage: convertToTwoDecimals(props.original.projectedPercentage),
      displayByCommaValue: originalDataFormatted(
        props.original.projectedDifference
      ),
    },
    {
      originalDifference: props.original.optimisticDifference,
      originalPercentage: props.original.optimisticPercentage,
      difference: numConversion(props.original.optimisticDifference),
      percentage: convertToTwoDecimals(props.original.optimisticPercentage),
      displayByCommaValue: originalDataFormatted(
        props.original.optimisticDifference
      ),
    },
  ];
  const target = {
    displayValue: Target,
    original: props.original.targetAmount,
    displayByCommaValue: originalDataFormatted(props.original.targetAmount),
  };
  return (
    <div className="channel-view-card">
      {/* <div className="channel-view-top"> */}
      <div className="channel-name">{props.channelName}</div>
      <div className="channel-right">
        <div className="horizotal-progressbar">
          <HorizotalProgressBar
            committedPercentage={Committed > Target ? 100 : committedPercentage}
            optimisticPercentage={optimisticPercentage>100 ?100:optimisticPercentage }
            projectedPercentage={projectedPercentage >100 ?100 : projectedPercentage}
            committedColor={props.progressStatus.committedColor}
            optimisticColor={props.progressStatus.optimisticColor}
            projectedColor={props.progressStatus.projectedColor}
            gapToTargetColor={props.progressStatus.gapToTargetColor}
            targetPosition={props.progressStatus.targetPosition}
            flagValue={originalDataFormatted(props.progressStatus.flagValue)}
          />
        </div>
        {/* </div> */}
        <div className="channel-view-card-right">
          <div className="card-list">
            <ChannelViewDetailsCard
              amount={Committed > Target ? "100*" : committedPercentage}
              description="Completed"
              // color="#DB4833"
            />
            <div className="channel-data">
              <ChannelData
                className="channel-view-container"
                channelOverAllArray={channelOverAllArray}
                weakOnweakStatus={weakOnweakStatus}
                target={target}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelViewCard;
