import React from "react";
import "../ChannelBox/ChannelBox.scss";

const ChannelBox = ({ color, name }) => (
  <div className="channel-box">
    <div className="channel-color">
      <div className={`box ${color}`} />
    </div>
    <div className="channelName">{name}</div>
  </div>
);
export default ChannelBox;
