import Settings from "../../Trash/Settings/Settings";
import Performance from "../Performance/Performance";
import OverallStatus from "../OverallStatus/OverallStatus";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import "./Dashboard.scss";
import {
  numConversion,
  convertToTwoDecimals,
} from "../../utility/dataManipulation/DataConversion";
import SalesDashboard from "../SalesDashboard/SalesDashboard";

const Dashboard = (props) => {
  //formating api data
  let target = {
    display: "Target",
    original: props.data.targetAmount,
    displayValue: Math.round((props.data.targetAmount / 10000000) * 100) / 100,
    displayByCommaValue:
      props.data.targetAmount &&
      props.data.targetAmount.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
      }),
  };

  let overAllStatus = [
    {
      display: "Gap to Target",
      original: props.data.gapToTarget,
      displayValue: Math.round((props.data.gapToTarget / 10000000) * 100) / 100,
      displayByCommaValue:
        props.data.gapToTarget &&
        props.data.gapToTarget.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      display: "Commited",
      original: props.data.committedAmount,
      displayValue:
        Math.round((props.data.committedAmount / 10000000) * 100) / 100,
      displayByCommaValue:
        props.data.committedAmount &&
        props.data.committedAmount.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      display: "Projected",
      original: props.data.projectedAmount,
      displayValue:
        Math.round((props.data.projectedAmount / 10000000) * 100) / 100,
      displayByCommaValue:
        props.data.projectedAmount &&
        props.data.projectedAmount.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      display: "Optimistic",
      original: props.data.optimisticAmount,
      displayValue:
        Math.round((props.data.optimisticAmount / 10000000) * 100) / 100,
      displayByCommaValue:
        props.data.optimisticAmount &&
        props.data.optimisticAmount.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
  ];
  let weakOnweakStatus = [
    {
      originalDifference: props.data.committedDifference,
      originalPercentage: props.data.committedPercentage,
      difference: numConversion(props.data.committedDifference),
      percentage: convertToTwoDecimals(props.data.committedPercentage),
      displayByCommaValue:
        props.data.committedDifference &&
        props.data.committedDifference.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      originalDifference: props.data.projectedDifference,
      originalPercentage: props.data.projectedPercentage,
      difference: numConversion(props.data.projectedDifference),
      percentage: convertToTwoDecimals(props.data.projectedPercentage),
      displayByCommaValue:
        props.data.projectedDifference &&
        props.data.projectedDifference.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
    {
      originalDifference: props.data.optimisticDifference,
      originalPercentage: props.data.optimisticPercentage,
      difference: numConversion(props.data.optimisticDifference),
      percentage: convertToTwoDecimals(props.data.optimisticPercentage),
      displayByCommaValue:
        props.data.optimisticDifference &&
        props.data.optimisticDifference.toLocaleString("en-IN", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "INR",
        }),
    },
  ];
  return (
    <>
      <BrowserRouter basename="/">
        <DashboardHeader welcome={props.welcome} />
        <Routes>
          {(props.salesDashboard || props.financeDashboard) && (
            <>
              {props.salesDashboard && (
                <Route path="/salesdashboard" element={<SalesDashboard />} />
              )}
              {props.financeDashboard && (
                <Route
                  path="/"
                  element={
                    <div className="dashboardbody">
                      <Performance
                        overAllStatus={overAllStatus}
                        weakOnweakStatus={weakOnweakStatus}
                        target={target}
                      />
                      <OverallStatus />
                    </div>
                  }
                />
              )}
            </>
          )}

          {/* <Route path="/salesdashboard" element={<SalesDashboard />} />
          <Route path="/settings" element={<Settings />} /> */}
          {/* {props.salesDashboard === "sales" && (
            <>
              <Route path="/salesdashboard" element={<SalesDashboard />} /> : "
              "
            </>
          )}
          {props.financeDashboard === "Finance" && (
            <>
              <Route
                path="/"
                // element={<SalesDashboard />}
                element={
                  <div className="dashboardbody">
                    <Performance
                      overAllStatus={overAllStatus}
                      weakOnweakStatus={weakOnweakStatus}
                      target={target}
                    />
                    <OverallStatus />
                  </div>
                }
              />
              : ""
            </>
          )} */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Dashboard;
