import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./DropDownSelect.scss";

export const DropDownSelect = (props) => {
  /** @dropDownOptions  are in the format [{ value: 1, label: "One" }]  */
  /**  @dropDownLabel  is a string Value */
  /**  @selectedOptionCallBack  is a callback function which return the value to the parent */

  const { dropDownOptions, selectedOptionCallBack, dropDownLabel } = props;
  const [dropDownVal, setDropDownVal] = React.useState(
    dropDownOptions[0].value
  );
  const handleChange = (event) => {
    setDropDownVal(event.target.value);
    selectedOptionCallBack(event.target.value);
  };

  return (
    <div className="dropDownContainer">
      <div>{dropDownLabel}</div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          id="demo-simple-select-standard"
          value={dropDownVal}
          onChange={handleChange}
          label=""
          sx={{
            "& .MuiSelect-selectMenu": {
              borderBottom: "none",
            },
          }}
        >
          {dropDownOptions.map((obj) => {
            return <MenuItem value={obj.value}>{obj.label}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropDownSelect;
