import axios from "axios";
import { channelViewApiUrl, reloadApiUrl } from "../constants/apis";
const refreshApi = async () => {
  try {
    const response = await axios({
      method: "get",
      url: reloadApiUrl,
    });
    await new Promise((resolve) => setTimeout(resolve, 5000));
    console.log("allOwners", response.data);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
export default refreshApi;
