//------------dropdown data-----------
//used in 
export const channel = [
  {
    id: 0,
    name: "Channel",
  },
  {
    id: 1,
    name: "User",
  },
];
export const queterly_summery_data = [
  {
    id: 0,
    name: "FY2024",
  },
  {
    id: 1,
    name: "Q1 2024",
  },
  // {
  //   id: 2,
  //   name: "Q2 2024",
  // },
  // {
  //   id: 3,
  //   name: "Q3 2024",
  // },
  // {
  //   id: 4,
  //   name: "Q4 2024",
  // },
];

export const owners = [
  {
    id: 0,
    name: "All Owners",
  },
  {
    id: 1,
    name: "Sumit",
  },
  {
    id: 14,
    name: "Shashank",
  },
  {
    id: 15,
    name: "Aditi",
  },
  {
    id: 10,
    name: "Saikamal",
  },
  {
    id: 11,
    name: "Viswa",
  },
];

export const userdatalist = [
    {
        "ownerName": "Sumit",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 3788900,
                "projectedAmount": 5167500,
                "gapToTarget": -3788900,
                "optimisticAmount": 5167500
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 1400000
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Naveen",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Sahil",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Eeshwar",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Dhanush",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Sushree",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Kashif",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Gopal",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Tulasi",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Saikamal",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 5700000
            },
            {
                "channelName": "Outbound",
                "targetAmount": 10000000,
                "committedAmount": 6967720,
                "projectedAmount": 12998920,
                "gapToTarget": 3032280,
                "optimisticAmount": 12998920
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 22251220,
                "projectedAmount": 45305300,
                "gapToTarget": -22251220,
                "optimisticAmount": 45305300
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 70000000,
                "committedAmount": 1788000,
                "projectedAmount": 1788000,
                "gapToTarget": 68212000,
                "optimisticAmount": 3263000
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Viswa",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 19500000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 19500000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 27500000,
                "committedAmount": 3715659,
                "projectedAmount": 4916659,
                "gapToTarget": 23784341,
                "optimisticAmount": 5416659
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 16378820,
                "projectedAmount": 20406380,
                "gapToTarget": -16378820,
                "optimisticAmount": 21406380
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 996000,
                "projectedAmount": 996000,
                "gapToTarget": -996000,
                "optimisticAmount": 996000
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 12000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 12000000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 35000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 35000000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 5000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 5000000,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Aditi",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 18500000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 18500000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 20000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 20000000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 9000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 9000000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 28500000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 28500000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 5000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 5000000,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "Shashank",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 10000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 10000000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "None",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 110000000,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 110000000,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    },
    {
        "ownerName": "NewHire",
        "channels": [
            {
                "channelName": "Inbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Outbound",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Existing Business",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Account Mining",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "CXO Sales",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Network",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            },
            {
                "channelName": "Partner",
                "targetAmount": 0,
                "committedAmount": 0,
                "projectedAmount": 0,
                "gapToTarget": 0,
                "optimisticAmount": 0
            }
        ]
    }
]