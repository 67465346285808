import UserViewProgress from "../commonComponents/UserViewProgressBar/UserViewProgress";
import ProgressBar from "../commonComponents/OverallProgressbar/OverallProgressbar";
import "../UserView/UserView.scss";
const UserView = (props) => {
  const usersData = props.amount;
  return (
    <>
      <div className="userchannelviewheader">
        <div className="Channel-user-heading"> User View</div>
        <div className="user-view-card">
          {usersData.map((userData, index) => (
            <div className="user-view" key={index}>
              <div className="owner-name">{userData.ownerName}</div>
              <div className="owner-progress-bar">
                <ProgressBar amount={userData.channels} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserView;
