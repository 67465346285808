import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "./DonutChart.scss";
import { Tooltip } from "@mui/material";

const DonutChart = ({ channelsData }) => {

  const [overallData, setOverallData] = useState([]);
  useEffect(() => {
    setOverallData(channelsData);
  }, [channelsData]);

  const crore = 10000000;
  const donutLabelsData = [];
  const donutLabelsPercent = [];
  const donutColors = [];
  const channelsTotalAmounts = [];
  const combinedData = [];

  const gapToTargetSum = Math.max(
    channelsData.reduce((total, channel) => {
      const gap = (channel.targetAmount - channel.committedAmount) / crore;
      return total + parseFloat(gap);
    }, 0),
    0
  );

  const gapToTargetSumTooltipValue = channelsData.reduce((total, channel) => {
    const gap = channel.targetAmount - channel.committedAmount;
    return total + gap;
  }, 0);
  
  // const gapToTargetSumTooltipValue = channelsData.data.reduce((total, channel) => {
  //   const gap =
  //     (Number(channel.targetAmount) - Number(parseFloat(channel.committedAmount) || 0)) / crore;
  //   // const positiveGap = Math.max(parseFloat(gap), 0);
  
  //   return total + gap;
  // }, 0);

  const overAllTargetAmount = channelsData.reduce((total, channel) => {
    const overall = channel.targetAmount / crore;
    return total + parseFloat(overall);
  }, 0);

  const overAllCommitedAmount = channelsData.reduce((total, channel) => {
    const overall = channel.committedAmount / crore;
    return total + parseFloat(overall);
  }, 0);

  const gapToTargetSumPercentage =
    (overAllCommitedAmount * 100) / overAllTargetAmount;
  const gapToTargetOverallSumPercentage = 100 - gapToTargetSumPercentage;

  const channelColors = {
    "Account Mining": "#76C894",
    Outbound: "#611FED",
    "Marketing Inbound": "#54A6C0",
    "CXO Sales": "#CC2877",
    "Network Inbound": "#C8DB57",
    Partner: "#76CAFD",
    "Existing Business": "#FF9518",
    "Gap target": "#B8B8B8",
  };

  const donutChannelData = () => {
    overallData?.forEach((channel, index) => {
      const channelsLabels = channel.channelName;
      donutLabelsData.push(channelsLabels);

      let percent = (
        (channel.committedAmount / channel.targetAmount) *
        100
      ).toFixed(1);

      let channelsTotalAmount;
      channelsTotalAmount = (channel.committedAmount / crore).toFixed(2);
      // if (channel.targetAmount >= channel.committedAmount || channel.targetAmount <= channel.committedAmount) {
      // }
      

      combinedData.push({
        percent: (
          (channel.committedAmount / channel.targetAmount) *
          100
        ).toFixed(1),
        totalAmount: channelsTotalAmount,
      });

      channelsTotalAmounts.push(channelsTotalAmount);
      let overall = (10000 / 10000) * 100;
      percent = Math.min(percent, overall);
      donutLabelsPercent.push(percent);
      donutColors.push(channelColors[channelsLabels]);
    });

    if (gapToTargetSumPercentage !== 0) {
      donutLabelsData.push("Gap target");
      donutLabelsPercent.push(Math.round(gapToTargetSumPercentage));
      donutColors.push(channelColors["Gap target"]);
    } else {
      donutLabelsData.push("Gap target");
      donutLabelsPercent.push(gapToTargetOverallSumPercentage);
      donutColors.push("#B8B8B8");
    }
    combinedData.push({
      percent: gapToTargetSumPercentage.toFixed(2),
      totalAmount: gapToTargetSum.toFixed(2),
    });

    return {
      donutLabelsData,
      donutLabelsPercent,
      channelsTotalAmounts,
      donutColors,
      combinedData,
    };
  };

  const result = donutChannelData();

  const donutData = {
    labels: result.donutLabelsData,
    datasets: [
      // {
      //   data: result.combinedData.map((item) => item.percent),
      //   backgroundColor: result.donutColors,
      //   label: "Percentage",
      // },
      {
        data: result.combinedData.map((item) => item.totalAmount),
        backgroundColor: result.donutColors,
      },
    ],
  };

  const options = {
    cutout: 100,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (context) => {
            const index = context[0].dataIndex;
            return result.donutLabelsData[index];
          },
          label: (context) => {
            const value = context.formattedValue;
            return value + " Cr"; 
          },
        },
      
      },
      center: {
        color: "#red",
        fontStyle: "bold",
        lineHeight: 1.6,
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
  };

  return (
    <div className="doughNutChartSection">
      <Doughnut data={donutData} options={options} className="doughNutChart" />
      <div className="doughNutChartOverallTarget">
        <Tooltip
          title={`${(overAllCommitedAmount * crore).toFixed(0)}`}
          arrow
          placement="bottom"
        >
          <div className="doughNutChartOverallCommitedAmount">
            {overAllCommitedAmount.toFixed(2)} Cr
          </div>
        </Tooltip>
        /
        <Tooltip
          title={`${(overAllTargetAmount * crore).toFixed(0)}`}
          arrow
          placement="bottom"
        >
          <div className="doughNutChartOverallTargetAmount">
            {overAllTargetAmount.toFixed(2)} Cr
          </div>
        </Tooltip>
        <div className="doughNutChartOverallTargetPercentage">{`(${(
          ((overAllCommitedAmount * crore).toFixed(0) /
            (overAllTargetAmount * crore).toFixed(0)) *
          100
        ).toFixed(2)}%)`}</div>
      </div>
      <div className="doughNutChartOverallTargetLabel">Achieved</div>
      <div className="doughNutChartTarget">
        <Tooltip
          title={`${(gapToTargetSumTooltipValue).toFixed(0)}`}
          arrow
          placement="bottom"
        >
          <div>{gapToTargetSum.toFixed(2)} Cr</div>
        </Tooltip>
        <br />
        <span>Gap Target</span>
      </div>
    </div>
  );
};

export default DonutChart;
