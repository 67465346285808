import axios from "axios";
import { ownersApiUrl } from "../constants/apis";

export async function userListApi() {
  try {
    const response = await axios({
      method: "get",
      url: ownersApiUrl,//owners
    });
    const data = response.data;
    console.log("owners list",data)

    const users = ["Saikamal", "Viswa", "Aditi", "Shashank","Sumit"];
    const filteredData = data.filter((item) => {
      return users.includes(item.name);
    });
    // console.log("owners list",data)
    const allOwners = { id: 0, name: "All Owners" };
    const newData = [allOwners, ...data];
    return newData;
  } catch (error) {
    console.log(error);
  }
}
