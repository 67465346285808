import ChannelViewCard from "../commonComponents/ChannelViewCard/ChannelViewCard";
import "./ChannelView.scss";

const ChannelView = (props) => {
  const progressStatus = [
    {
      committedColor: "rgba(97, 31, 237)",
      projectedColor: "rgba(97, 31, 237,0.699)",
      optimisticColor: "rgba(97, 31, 237,0.4)",
      // gapToTargetColor: "rgba(97, 31, 237,0.1)",
      // optimisticPosition: "74%",
      targetPosition: "84.2%",
      flagValue: "",
    },
    {
      committedColor: "rgba(84, 166, 192)",
      // gapToTargetColor: "",
      projectedColor: "rgba(84, 166, 192,0.699)",
      optimisticColor: "rgba(84, 166, 192,0.4)",
      // optimisticPosition: "64%",
      targetPosition: "77%",
      flagValue: "",
    },
    {
      committedColor: "rgba(118, 202, 253)",
      // gapToTargetColor: "",
      projectedColor: "rgba(118, 202, 253,0.699)",
      optimisticColor: "rgba(118, 202, 253,0.4)",
      // optimisticPosition: "-1.2%",
      targetPosition: "96.2%",
      flagValue: "",
    },
    {
      committedColor: "rgba(200, 219, 87)",
      // gapToTargetColor: "",
      projectedColor: "rgba(200, 219, 87,0.699)",
      optimisticColor: "rgba(200, 219, 87,0.4)",
      // optimisticPosition: "81%",
      targetPosition: "91%",
      flagValue: "",
    },
    {
      committedColor: "rgba(118, 200, 148)",
      // gapToTargetColor: "",
      projectedColor: "rgba(118, 200, 148,0.699)",
      optimisticColor: "rgba(118, 200, 148,0.4)",
      // optimisticPosition: "69%",
      targetPosition: "85%",
      flagValue: "",
    },
    {
      committedColor: "rgba(255, 149, 24)",
      // gapToTargetColor: "",
      projectedColor: "rgba(255, 149, 24,0.699)",
      optimisticColor: "rgba(255, 149, 24,0.4)",
      // optimisticPosition: "68%",
      targetPosition: "82%",
      flagValue: "",
    },
    {
      committedColor: "rgba(204, 40, 119)",
      // gapToTargetColor: "",
      projectedColor: "rgba(204, 40, 119,0.699)",
      optimisticColor: "rgba(204, 40, 119,0.4)",
      // optimisticPosition: "68%",
      targetPosition: "82%",
      flagValue: "",
    },
    {
      committedColor: "#9f5b96",
      // gapToTargetColor: "rgb(150 110 237)",
      projectedColor: "#EDEDED",
      optimisticColor: "#EDEDED",
      targetPosition: "52%",
      flagValue: "",
    },
    {
      committedColor: "#17f20b",
      projectedColor: "#EDEDED",
      optimisticColor: "#EDEDED",
      targetPosition: "72%",
      flagValue: "",
    },
  ];
  for (let i = 0; i < props.amount.length; i++) {
    // if (props.amount[i].targetAmount > props.amount[i].optimisticAmount) {
    //   // progressStatus[i].targetPosition = "98.6%";
    //   progressStatus[i].targetPosition =
    //     (props.amount[i].optimisticAmount / props.amount[i].targetAmount) *
    //       100 -
    //     0.1 ;
    //     progressStatus[i].flagValue=props.amount[i].optimisticAmount;
    // } else if (
    //   props.amount[i].targetAmount === props.amount[i].optimisticAmount
    // ) {
    //   progressStatus[i].targetPosition = "-0.1";
    //   progressStatus[i].flagValue=props.amount[i].optimisticAmount;

    //   // progressStatus[i].optimisticPosition = "98.6%";
    // } else {
    //   // progressStatus[i].optimisticPosition = "98.6%";
    //   progressStatus[i].targetPosition =
    //     (props.amount[i].targetAmount / props.amount[i].optimisticAmount) *
    //       100 -
    //     0.1 ;
    //     progressStatus[i].flagValue=props.amount[i].targetAmount;
    // }
    if(props.amount[i].hasOwnProperty('channelName'))
    {

    if (props.amount[i].targetAmount > props.amount[i].optimisticAmount) {
      progressStatus[i].targetPosition = 99.8;
    } else {
      progressStatus[i].targetPosition =props.amount[i].optimisticAmount===0?0:
        (props.amount[i].targetAmount / props.amount[i].optimisticAmount) *
          100 -
        0.1;
    }
    progressStatus[i].flagValue = props.amount[i].targetAmount;
  }
}
  return (
    <>
      <div className="channelView">
        <div className="channelviewheader">
          <div className="Channel-name-heading"> Channel View</div>
        </div>
        <div className="channeldatacontainer">
          {props.amount.map((item, index) => {
            if(item.hasOwnProperty('channelName'))
            return (
              <ChannelViewCard
                original={item}
                channelName={item.channelName}
                progressStatus={progressStatus[index]}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChannelView;
