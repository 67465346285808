import React, { useEffect, useState } from "react";
import { Progress } from "rsuite";
import "./ProgressBar.scss";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Padding,
} from "@mui/icons-material";
import { Grid, IconButton, ThemeProvider, Tooltip, createTheme, useMediaQuery } from "@mui/material";

const ProgressBar = (props) => {

    const {channelData, overAllGapToTarget,overAllTarget} = props;
    const[overallData, setOverallData] = useState([])
    useEffect(() => {
      setOverallData(channelData)
    },[channelData])

  const [currentIndex, setCurrentIndex] = useState(0);
  const barsToShow = 4;
  const [showPrevious, setShowPrevious] = useState(false);
  const crore = 10000000;
  const dealsAmount = 3000000;
  
  const theme = createTheme();
  const is1440 = useMediaQuery(theme.breakpoints.between(1360, 1700));
  const is1024to1280 = useMediaQuery(theme.breakpoints.between(1100, 1360));
  const isBelow1024 = useMediaQuery(theme.breakpoints.down(900,1100));
  const isBelow900 = useMediaQuery(theme.breakpoints.down(700,900));
  const isAbove1440 = useMediaQuery(theme.breakpoints.up(1440) && theme.breakpoints.down(1920));

  let adjustedBarsToShow = barsToShow;
  if (is1440) {
    adjustedBarsToShow = 4;
  } else if (is1024to1280) {
    adjustedBarsToShow = 3;
  } else if (isBelow1024) {
    adjustedBarsToShow = 2;
  }  else if (isBelow900) {
    adjustedBarsToShow = 1;
  }
  else if (isAbove1440) {
    adjustedBarsToShow = 4;
  }

  const maxIterations = Math.ceil(overallData.length / barsToShow);
  const handleArrowClick = () => {
    if (showPrevious) {
      setCurrentIndex(currentIndex - barsToShow);
    } else {
      const nextIndex = currentIndex + barsToShow;
      if (nextIndex < maxIterations * barsToShow) {
        setCurrentIndex(nextIndex);
      }
    }
    setShowPrevious(!showPrevious);
  };

  const channelColors = {
    "Account Mining": "#76C894",
    "Outbound": "#611FED",
    "Marketing Inbound": "#54A6C0",
    "CXO Sales": "#CC2877",
    "Network Inbound": "#C8DB57",
    "Partner": "#76CAFD",
    "Existing Business": "#FF9518",
  };
  
  const donutData = {
    datasets: [
      {
        backgroundColor: Object.values(channelColors),
      },
    ],
  };

  const gapToTargetSum = channelData.reduce((total, channel) => {
    const gap = (channel.targetAmount - channel.committedAmount) / crore;
    return (total + parseFloat(gap));
  }, 0).toFixed(2);

  const overAllTargetAmount = channelData.reduce((total, channel) => {
    const overall = channel.targetAmount / crore
    return (total + parseFloat(overall));
  }, 0).toFixed(2);

  overAllGapToTarget(gapToTargetSum);
  overAllTarget(overAllTargetAmount);
  
  return (
    
    <ThemeProvider theme={theme}>
    <div className="progressBarMianContainer">
        
    {overallData?.slice(currentIndex, currentIndex + adjustedBarsToShow).map((channel, index) => {
        if(channel.channelName) {
                
          const remaining = channel.targetAmount - channel.committedAmount;
          const nonNegativeGapToTarget = remaining < 0 ? 0.00 : remaining;
          const totalDeals = Math.round(remaining / dealsAmount)
          const nonNegativeTotalDeals = totalDeals < 0 ? 0 : totalDeals;
          let percent = ((channel.committedAmount / channel.targetAmount) * 100).toFixed(2);
          percent = Math.min(percent, 100);
          const tooltipPercent = ((channel.committedAmount / channel.targetAmount) * 100).toFixed(2);
          const remaingGraph = ((remaining / channel.targetAmount) * 100);
          const nonNegativeDeviationTarget = remaingGraph < 0 ? 0.00 : remaingGraph;
          
          return (
            <div className="progressBarMainSection">
              <div className="progressBarSection">
                <div className="progressBar">
                  <Progress.Line
                    percent={percent}
                    strokeWidth={30}
                    strokeColor={channelColors[channel.channelName]}
                    trailColor="#d1d0cf"
                    strokeLinecap="square"
                    style={{ height: "100px", width: "30%" }}
                  />
                </div>
                <div className="progressBarSideContent">
                  <div className="dealsSection">
                    <div className="dealsNumbers">
                      {nonNegativeTotalDeals}<span>Deals</span>
                    </div>
                    <div className="dealContent">To Recover</div>
                  </div>
                  <div className="deviationTargetSec">
                    <div className="deviationNumbers">
                      {(nonNegativeDeviationTarget).toFixed(2)} % <br />
                    </div>
                    <div className="deviationTargetName">
                      Deviation to Target
                    </div>
                  </div>
                  <div className="gapTargetSec">
                      <Tooltip title={`${remaining}`} arrow placement="bottom">
                        <div className="gapTargetNumbers">{(nonNegativeGapToTarget / crore).toFixed(2)} Cr <br /></div>
                      </Tooltip>
                    <div className="gapTargetname">Gap to Target</div>
                  </div>
                </div>
              </div>

              <div className="progressBarChannelsSection">
                <div className="chanelsSection">
                  <div className="roundedBorder" style={{ backgroundColor: channelColors[channel.channelName] }}>{donutData.datasets.backgroundColor}</div>
                  <div className="channelsName">
                    {channel.channelName} {`(${tooltipPercent}%)`}
                  </div>
                </div>
                <div className="achivedSection">
                  <Tooltip title={`${channel.committedAmount}`} arrow placement="bottom">
                    <span style={{ paddingRight: "5px" }}>{(channel.committedAmount / crore).toFixed(2)} Cr</span>
                  </Tooltip>
                  /
                  <Tooltip title={`${channel.targetAmount}`} arrow placement="bottom">
                    <span style={{ paddingLeft: "5px" }}>{(channel.targetAmount / crore).toFixed(2)} Cr</span>
                  </Tooltip>
                  <div className="achivedText">Achieved</div>
                </div>
              </div>
            </div>
          );
        }
        })}
    </div>

      <div className="showMoreButton">
        <IconButton onClick={handleArrowClick} size="large" style={{ fontSize: "32px" }}>
          <KeyboardArrowDown style={{transform: showPrevious ? "rotate(90deg)" : "rotate(270deg)",}} fontSize="large"/>
        </IconButton>
      </div>
    
    </ThemeProvider>
  );
};

export default ProgressBar;
