import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { Progress } from "rsuite";
import "./HorizontalBar.scss";
import ideaIcon from "../../assets/images/idea.svg";


const HorizontalBar = (props) => {

    const { pipelineData, gapToTargetAmount ,overallChannelsData } = props;
    const crore = 10000000;
    const dealsAmount = 3000000;
    const tooltipGapToTargetSum = Math.max(overallChannelsData.reduce((total, channel) => {
      const gap = (channel.targetAmount - channel.committedAmount) / crore;
      return (total + parseFloat(gap));
    }, 0), 0);
    const lastChannel = pipelineData[pipelineData.length - 1];
 
    let overallGapAmount;
    let OverallDeals;
    let totalPending;
    let deals;
    let tootipValue;
    let tooltipPending;
    let overallPending;
    let overallPendingTarget;
    let totalTargetAmount;
    let overallTotalAmountTooltip;
    let totalLastDeals;
    
    if (lastChannel) {
      overallGapAmount = Math.round((gapToTargetAmount * 8) * 2);
      // const overallSum = (lastChannel.accomplished / crore).toFixed(2);
      // overallPending = overallGapAmount - overallSum;
      OverallDeals = Math.round(overallGapAmount / dealsAmount);
    }
    

  return (
    <>
      <div className="progressBarMianContainer">
        {pipelineData?.map((channel, index) => {
          totalTargetAmount = (tooltipGapToTargetSum * 1.25 * crore);
          const overallSum = (channel.accomplished / crore).toFixed(2);
          
          if (index === 0) {
            overallGapAmount = gapToTargetAmount * 1.25;
            tootipValue = (totalTargetAmount - channel.accomplished)
            overallPendingTarget = (overallGapAmount - overallSum)
            OverallDeals = (overallPendingTarget * crore) / dealsAmount;
            overallTotalAmountTooltip = totalTargetAmount

          } else if (index === 1) {
            overallTotalAmountTooltip = tootipValue * 2
            totalPending = overallPendingTarget * 2;
            overallGapAmount = totalPending;
            tooltipPending = (tootipValue * 2) - (channel.accomplished)
            tootipValue = tooltipPending
            overallPendingTarget = (overallGapAmount - overallSum)
            OverallDeals = (overallPendingTarget * crore) / dealsAmount;

          } else if (index === 2) {
            overallTotalAmountTooltip = tootipValue * 1
            totalPending = overallPendingTarget * 1;
            overallGapAmount = totalPending;
            tooltipPending = (tootipValue * 1) - (channel.accomplished)
            tootipValue = tooltipPending
            overallPendingTarget = (overallGapAmount - overallSum)
            OverallDeals = (overallPendingTarget * crore) / dealsAmount;

          } else if (index === 3) {
            overallTotalAmountTooltip = tootipValue * 2
            totalPending = overallPendingTarget * 2;
            overallGapAmount = totalPending;
            tooltipPending = (tootipValue * 2) - (channel.accomplished)
            tootipValue = tooltipPending
            overallPendingTarget = (overallGapAmount - overallSum)
            OverallDeals = ((overallPendingTarget * crore) / dealsAmount);
            totalLastDeals = (((Math.max(OverallDeals,0.00)).toFixed(0) * 3))

          }
          
          overallPending = overallGapAmount - overallSum;
          const overallTooltipPending = ((tootipValue * crore) - channel.accomplished)
          const TotalGapBar = (overallSum * 100) / overallGapAmount;
          const nonNegativeTotalGapBar = Math.min(Math.max(TotalGapBar, 0), 100);
          
          

          const totalTargetBar = ( overallGapAmount * 100 ) / overallGapAmount
  
          return (
            <div className="horizontalBarMainSection">
              <div>
                <Typography className="horizontalBarName">
                  {channel.id}
                </Typography>
                <div className="achivedSection">
                  <Tooltip title={`${(channel.accomplished).toFixed(0)}`} arrow placement="bottom">
                  <span style={{marginRight:'5px'}}>{Math.max((overallSum),0.00).toFixed(2)} Cr</span>
                  </Tooltip>
                  /
                  <Tooltip title={`${(overallTotalAmountTooltip).toFixed(0)}`} arrow placement="bottom">
                  <span style={{marginLeft:'5px'}}>{Math.max((overallTotalAmountTooltip / crore),0.00).toFixed(2)} Cr</span>
                  </Tooltip>
                  <Typography className="achivedText">
                    Target Achieved
                  </Typography>
                </div>
                <div className="horizontalUpperGrayBAr">
                  <Progress.Line
                    percent={totalTargetBar}
                    strokeWidth={30}
                    strokeColor="#D9D9D9"
                    trailColor="#D9D9D9"
                    strokeLinecap="square"
                    style={{ height: "", width: "30%" }}
                  />
                </div>
                <div className="">
                  <Progress.Line
                    percent={nonNegativeTotalGapBar}
                    strokeWidth={30}
                    strokeColor="#008000"
                    trailColor="#D9D9D9"
                    strokeLinecap="square"
                    style={{ height: "", width: "30%" }}
                  />
                </div>
              </div>

              <div className="horizontalBarDealsAndPendingSection">
                <div className="dealsSection">
                  <div className="dealsNumbers">
                    {((Math.max(OverallDeals,0.00)).toFixed(0))}
                    <span>Deals</span>
                  </div>
                  <Typography className="dealContent">To Recover</Typography>
                </div>

                <div className="gapTargetSec">
                  <Tooltip title={`${(tootipValue).toFixed(0)}`} arrow placement="bottom">
                  <div className="gapTargetNumbers">
                    {Math.max((overallPendingTarget),0.00).toFixed(2)}Cr <br />
                  </div>
                  </Tooltip>
                  <Typography className="gapTargetname">Pending</Typography>
                </div>
              </div>
            </div>
            
          );
          // }
        })}
        
      </div>
      {lastChannel && (
        <div className="horizontalBarSectionBottomLabel">
          <img src={ideaIcon} alt="idea icon" />
          <div>
            You need at least {Math.round(Math.max((totalLastDeals * 3),0))} Leads or {Math.round(Math.max((totalLastDeals),0))} Meetings to reach the Target.
          </div>
        </div>
      )}
    </>
    
  );
};

export default HorizontalBar;
